<template>
  <div class="form-content-composer-options-image">
    <multi-file-upload
        class=" mb-2"
        :button-label-html-first-item="$t('report_editor.image')"
        :allowed-mime-types="allowedMimeTypes"
        :allowed-file-extensions="allowedFileExtensions"
        :max-file-count="1"
        :value="uploadValue"
        @input="uploadFile"
        @validity-change.prevent="$emit('validity-change', $event)"
    />
  </div>
</template>

<script>
import FormInput from '@pixelstein/ps-form/components/PsFormInput'
import MultiFileUpload from '@/components/Reports/MultiFileUpload.vue'
import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'

export default {
  name: 'FormContentComposerOptionsImage',
  components: { MultiFileUpload, FormInput },
  props: {
    value: { type: Object, default: null },
    allowedMimeTypes: { type: Array, default: () => ['image/png', 'image/jpeg'] },
    allowedFileExtensions: { type: Array, default: () => ['jpg', 'jpeg', 'png'] },
  },
  computed: {
    uploadValue() {
      if (this.value?.file) {
        return [this.value?.file]
      } else {
        return []
      }
    },
  },
  methods: {
    async uploadFile (newFiles) {
      if (newFiles?.progressingFiles?.length) {
        this.onInput('file', newFiles)
      } else {
        this.onInput('file')
      }
    },
    onInput (path, value) {
      const valueClone = _cloneDeep(this.value)

      _set(valueClone, path, value)

      if (path === 'file' && !value) {
        delete valueClone.file_id
        delete valueClone.options?.svgData
        delete valueClone.svgData
      }

      this.$emit('input', valueClone)
    },
  },
}
</script>
