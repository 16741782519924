<template>
  <div class="form-content-composer-options-condition">
    <template v-for="(val, idx) in value.values">
      <div class="bg-secondary text-white p-2 my-3">
        <button v-if="value?.values?.length > 1"
                class="btn btn-sm btn-secondary my-2 float-right"
                @click="removeComparison(idx)"
                type="button"
        >
          <i class="fa fa-close"/>
        </button>
        <form-select :label="$t('form_content_composer.options_condition.label.val_one')"
                     :placeholder="$t('form_content_composer.options_condition.label.val_one')"
                     :options="optionKeys"
                     :value="val.valOne"
                     required
                     option-value-key="value"
                     @input="onInput('valOne', idx, $event)">
        </form-select>
        <form-select
            :label="$t('form_content_composer.options_condition.label.comparison_types')"
            :placeholder="$t('form_content_composer.options_condition.placeholder_comparison_types')"
            :options="comparisonTypes"
            :value="val.comparison"
            required
            option-value-key="value"
            @input="onInput('comparison', idx, $event)"
        />
        <form-input v-if="val.comparison && !noSecondValueTypes.includes(val.comparison)"
                    :label="$t('form_content_composer.options_condition.label.val_two')"
                    :value="val.valTwo"
                    required
                    :options="optionKeys"
                    :option-label-key="'label'"
                    :option-value-key="'value'"
                    :list="'keySelectionVal2'"
                    @input="onInput('valTwo', idx, $event)"
                    @focus="toggleSelection(val, $event)"
        />
<!--        TODO: Show Selection just under AND for focused Input-->
      </div>
      <form-select v-if="value?.values?.length - 1 > idx"
        :label="$t('form_content_composer.options_condition.label.logic_gates')"
        :placeholder="$t('form_content_composer.options_condition.placeholder_logic_gates')"
        :options="logicGates"
        :value="val.gate"
        required
        option-value-key="value"
        @input="onInput('gate', idx, $event)">
      </form-select>
    </template>
    <button :disabled="value?.values?.length >= 3" type="button" class="btn btn-secondary" @click="addComparison">{{ $t('form_content_composer.options_condition.label.add_comparison_btn') }}</button>
  </div>
</template>

<script>
import FormInput from '@pixelstein/ps-form/components/PsFormInput'
import FormSelect from '@pixelstein/ps-form/components/PsFormSelect'
import _cloneDeep from 'lodash/cloneDeep.js'

export default {
  name: 'FormContentComposerOptionsConditionContainer',
  components: { FormInput, FormSelect},
  data() {
    return {
      currentInput: "",
      currentInputKey: "",
      keySelectionDisplay: false,
      showSelection: false,
      comparisonTypes: [
          { value: 'gt', label: this.$t('form_content_composer.options_condition.greater') },
          { value: 'gte', label: this.$t('form_content_composer.options_condition.greater_equal') },
          { value: 'eq', label: this.$t('form_content_composer.options_condition.equal') },
          { value: 'neq', label: this.$t('form_content_composer.options_condition.not_equal') },
          { value: 'lt', label: this.$t('form_content_composer.options_condition.smaller') },
          { value: 'lte', label: this.$t('form_content_composer.options_condition.smaller_equal') },
          { value: 'filter', label: this.$t('form_content_composer.options_condition.list_contains') },
          { value: 'empty', label: this.$t('form_content_composer.options_condition.empty') },
          { value: 'empty|not', label: this.$t('form_content_composer.options_condition.not_empty') },
          { value: 'eq(1)', label: this.$t('form_content_composer.options_condition.true') },
          { value: 'neq(1)', label: this.$t('form_content_composer.options_condition.false') }
        ],
      noSecondValueTypes: ['empty', 'empty|not', 'eq(1)', 'neq(1)'],
      logicGates: [
        { value: 'and', label: this.$t('form_content_composer.options_condition.and') },
        { value: 'or', label: this.$t('form_content_composer.options_condition.or') }
      ]
    }
  },
  props: {
    value: { type: Object, default: () => ({ values: [] }) },
    contentKeys: {type: Array, default: () => []},
  },
  computed: {
    optionKeys() {
      if (!this.contentKeys) {
        return []
      }

      return this.contentKeys.map(key => {
        return { value: key, label: key }
      })

    },
  },
  methods: {
    onInput(path, idx, value) {
      this.currentInput = value;

      let valueClone = _cloneDeep(this.value);

      if (!valueClone.values) {
        valueClone.values = []
      }

      if (path === null) {
        valueClone.values.push({valOne: "", valTwo: "", comparison: "", gate: ""})
      } else {
        valueClone.values[idx][path] = value;
      }

      this.$emit('input', valueClone)
    },
    addComparison() {
      this.onInput(null, this.value.values?.length ?? 0, null)
    },
    removeComparison(key) {
      if (this.value.values.length > 1) {
        let valueClone = _cloneDeep(this.value);
        valueClone.values.splice(key, 1)
        if (valueClone.values[key -1]) {
          valueClone.values[key -1].gate = ""
        }
        this.$emit('input', valueClone)
      }
    },
    toggleSelection(val, focus) {
      if (focus) {
        setTimeout(() => {this.showSelection = val},200)
      } else {
        setTimeout(() => {this.showSelection = null},100)
      }
    }
  },
  created () {
    if (!this.value.values) {
      this.addComparison()
    }
  }
}
</script>

<style scoped>

</style>