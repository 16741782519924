<template>
	<div class="form-content-composer-options-auto-complete">
    <form-input
        type="number"
        min-value="1"
        step="1"
        :label="$t('form_content_composer.options_input.label.maxRepeats')"
        :value="value.maxRepeats"
        :required="true"
        @input="onInput('maxRepeats', $event)"
    />
	</div>
</template>

<script>
import _set from 'lodash/set'
import _cloneDeep from 'lodash/cloneDeep'

import FormInput from '@pixelstein/ps-form/components/PsFormInput'

export default {
  name: 'FormContentComposerOptionsRepeat',
  components: { FormInput },
  props: {
    value: { type: Object, default: () => ({ values: [] }) },
  },
  methods: {
    onInput (path, value) {
      console.log('onInput', path, value)
      const valueClone = _cloneDeep(this.value)
      _set(valueClone, path, value)

      this.$emit('input', valueClone)
    },
  },
}
</script>
