<template>
  <div class="file-directory-menu">
    <div class="directions-outer-wrap">
      <div class="directories-wrap">
        <label class="directory"
               :class="{active: '' === value, muted: false}">
          <input type="radio" name="directories" @change="$emit('input', '')">
          {{ allLabel }}
        </label>
        <label v-for="directory in directories"
               :key="directory.id"
               class="directory"
               :class="{active: directory.id === value}">
          <input type="radio" name="directories" @change="$emit('input', directory.id)">
          {{ directory.name }}
        </label>
      </div>

      <a href="#"
         class="add-btn"
         :title="$t('directory_control.edit_directories')"
         @click.prevent="onEditDirectories"
         v-if="!readonly"
      >
        <svg-icon name="settings"/>
      </a>
    </div>
  </div>
</template>

<script>

import SvgIcon from "paperclip-lib/src/components/SvgIcon";

export default {
  name: 'FileDirectoryMenu',
  emits: ['input', 'edit'],
  components: {
    SvgIcon,
  },
  props: {
    directories: { type: Array, default: () => [] },
    directory: { type: Object, default: () => ({}) },
    value: { type: String, default: '' },
    allLabel: { type: String, default: 'All Files' },
    readonly: { type: Boolean, default: false },
  },
  methods: {
    onEditDirectories () {
      this.$emit('edit')
    }
  },
}
</script>
