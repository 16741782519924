<template>
  <div class="statistics-settings-data-set">
    <form-input
        required
        :label="$t('statistics.data_set.name')"
        :value="value.label"
        @input="onInput('label', $event)"
    />

    <div class="d-flex justify-content-between mb-4">
      <label>{{ $t('statistics.data_set.color') }}</label>
      <input-color-picker
          :value="value.color"
          @input="onInput(['color'], $event)"
      />
    </div>

    <h6>{{ $t('statistics.data_set.filter_header') }}</h6>
    <statistics-settings-dataset-filter
        v-for="(filter, idx) in value?.data_filter || []"
        :key="filter.field+idx"
        class="border-bottom"
        :report-fields="reportFields"
        :value="filter"
        @input="onInput(['data_filter', idx], $event)"
        @remove="removeFilter(idx)"
    />

    <div class="form-row align-items-end mt-3">
      <div class="col-9">
        <form-select
            :label="$t('statistics.data_set.add_new_filter')"
            option-value-key="value"
            option-label-key="label"
            :options="filterFields"
            v-model="newFilterKey"
        />
      </div>
      <div class="col-3">
        <button type="button"
                class="btn btn-secondary btn-block mb-3"
                :disabled="!newFilterKey"
                @click="addFilter"
        >
          <svg-icon name="plus"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import _cloneDeep from "lodash/cloneDeep";
import _set from "lodash/set";

import InputColorPicker from 'vue-native-color-picker'

import SvgIcon from "paperclip-lib/src/components/SvgIcon";

import FormInput from "@pixelstein/ps-form/components/PsFormInput";
import FormSelect from "@pixelstein/ps-form/components/PsFormSelect";
import StatisticsSettingsDatasetFilter from "@/components/Statistics/StatisticsSettingsDatasetFilter";

export default {
  name: "StatisticsSettingsDataSet",
  components: {
    StatisticsSettingsDatasetFilter,
    FormInput,
    FormSelect,
    SvgIcon,
    InputColorPicker,
  },
  props: {
    value: {
      type: Object, default: () => ({
        label: 'LABEL',
        data_filter: [
        ]
      })
    },
    reportFields: {type: Array, default: () => []},
  },
  data() {
    return {
      newFilterKey: '',
    }
  },
  computed: {
    filterOperators() {
      return [
        {
          label: this.$t('statistics.data_set.data_filter.operators.is'),
          value: '==='
        },
        {
          label: this.$t('statistics.data_set.data_filter.operators.is_not'),
          value: '!=='
        }
      ]
    },
    filterFields() {
      return this.reportFields
          .filter(field => !!['radio', 'checkbox', 'select'].find(type => field?.options?.type === type))
          .map(field => ({
            label: field?.options?.label || field?.options?.name,
            value: field?.options?.name,
          }))
    }
  },
  methods: {
    addFilter() {
      const nextIndex = this.value?.data_filter?.length || 0;

      this.onInput(['data_filter', nextIndex], {
        field: this.newFilterKey,
        operator: '===',
        value: ''
      });

      this.newFilterKey = ''
    },
    removeFilter(idx) {
      let filterClone = _cloneDeep(this.value.data_filter);

      filterClone.splice(idx, 1)

      this.onInput('data_filter', filterClone)
    },
    onInput(path, value) {
      let updatedData = _cloneDeep(this.value);
      updatedData = _set(updatedData, path, value)

      this.$emit('input', updatedData)
    },
  }
}
</script>

<style scoped>

</style>
