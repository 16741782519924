<template>
  <div>
    <div v-if="!readonly" class="form-row bg-secondary text-white">
      <div class="col-6 text-center">
        <i class="fas fa-chevron-down"
           :class="{'text-primary':sorting === 'value-asc'}"
           @click="onSortOptions('value', 'asc')"
           aria-hidden="true"></i>
        &nbsp;
        <i class="fas fa-chevron-up"
           :class="{'text-primary':sorting === 'value-desc'}"
           @click="onSortOptions('value', 'desc')"
           aria-hidden="true"></i>
      </div>
      <div class="col-6 text-center">
        <i class="fas fa-chevron-down"
           :class="{'text-primary':sorting === 'label-asc'}"
           @click="onSortOptions('label', 'asc')"
           aria-hidden="true"></i>
        &nbsp;
        <i class="fas fa-chevron-up"
           :class="{'text-primary':sorting === 'label-desc'}"
           @click="onSortOptions('label', 'desc')"
           aria-hidden="true"></i>
      </div>
    </div>
    <div v-for="(option, idx) in value ?? []"
         :key="option.id"
         class="form-row"
         :class="{'border-bottom border-light': idx !== value.length - 1}"
    >
      <div class="col-12 text-right small pt-1">
        <template v-if="!readonly">
          <button class="btn btn-sm btn-primary mb-n3"
                  type="button"
                  @click.prevent="onMoveDown(idx)"
                  v-if="idx < value.length-1"
          >
            <i class="fas fa-chevron-down"
               aria-hidden="true"></i>
          </button>
          &nbsp;
          <button class="btn btn-sm btn-primary mb-n3"
                  type="button"
                  @click.prevent="onMoveUp(idx)"
                  v-if="idx > 0"
          >
            <i class="fas fa-chevron-up"
               aria-hidden="true"></i>
          </button>
        </template>
        <template v-if="canSelectDefault">
          &nbsp;
          <button type="button"
                  class="btn btn-sm btn-outline-secondary mb-n3"
                  @click.prevent="onToggleDefault(idx)"
          ><i class="far fa-circle-check" aria-hidden="true" v-if="isDefault(idx)"></i
          ><i class="far fa-circle" aria-hidden="true" v-else></i
          ></button>
        </template>
        <template v-if="!readonly">
          &nbsp;
          <button type="button"
                  class="btn btn-sm btn-outline-danger mb-n3"
                  @click.prevent="onRemoveOption(idx)"
          ><i class="fas fa-trash" aria-hidden="true"></i></button>
        </template>
      </div>
      <div class="col-6">
        <form-input
            :label="$t('form_content_composer.options_input.options.value_label')"
            :value="option.value"
            :disabled="readonly"
            @input="onUpdateOption([idx, 'value'], $event)"
        />
      </div>
      <div class="col-6">
        <form-input
            :label="$t('form_content_composer.options_input.options.label_label')"
            :value="option.label"
            :disabled="readonly"
            @input="onUpdateOption([idx, 'label'], $event)"
        />
      </div>
    </div>

    <button type="button"
            v-if="!readonly"
            class="btn btn-primary btn-block"
            @click="onAddOption(getEmptyOption())"
    >
      {{ $t('form_content_composer.options_input.options.add_button') }}
    </button>
  </div>
</template>

<script>
import { v1 } from 'uuid'
import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import FormInput from '@pixelstein/ps-form/components/PsFormInput'

export default {
  name: 'OptionsEditor',
  emits: ['input', 'update-default'],
  components: { FormInput },
  props: {
    value: { type: Array, default: () => [] },
    default: { type: Object, default: () => {} },
    readonly: { type: Boolean, default: false },
    canSelectDefault: { type: Boolean, default: false },
  },
  data () {
    return {
      sorting: '',
    }
  },
  methods: {
    onAddOption (value) {
      const newValue = _cloneDeep(this.value)
      newValue.push(value)

      this.onInput(newValue)
    },
    onUpdateOption (path, value) {
      const newValue = _cloneDeep(this.value)
      _set(newValue, path, value)

      this.onInput(newValue)
    },
    onInput (value) {
      this.$emit('input', value)
    },
    onRemoveOption (idx) {
      const newValue = _cloneDeep(this.value)
      newValue.splice(idx, 1)
      this.onInput(newValue)
    },
    onMoveDown (idx) {
      if (idx >= this.value.length - 1) {
        return
      }

      const newValue = _cloneDeep(this.value)
      const value = newValue[idx]
      newValue.splice(idx, 1)
      newValue.splice(idx + 1, 0, value)

      this.onInput(newValue)
    },
    onMoveUp (idx) {
      if (idx < 1) {
        return
      }

      const newValue = _cloneDeep(this.value)
      const value = newValue[idx]
      newValue.splice(idx, 1)
      newValue.splice(idx - 1, 0, value)

      this.onInput(newValue)
    },
    onSortOptions (prop, dir) {
      let sorter

      if (dir === 'asc') {
        this.sorting = prop + '-asc'
        sorter = (a, b) => a[prop].localeCompare(b[prop])
      } else {
        this.sorting = prop + '-desc'
        sorter = (a, b) => b[prop].localeCompare(a[prop])
      }

      const newValue = this.value.toSorted(sorter)

      this.onInput(newValue)
    },
    onToggleDefault (idx) {
      this.$emit('update-default', this.value[idx].value)
    },
    getEmptyOption () {
      return { label: '', value: '', id: v1() }
    },
    isDefault (idx) {
      return !!this.default?.[this.value?.[idx]?.value]
    },
  },
}
</script>
