<template>
  <span
      class="modal-wrap"
      @click.stop
  >
      <general-modal :title="modalTitle"
                     @close="$emit('close')">
        <template v-slot:default>
          {{ modalBody }}
          <slot name="modalContent"/>
        </template>
        <template v-slot:footer>
          <button type="button"
                  class="no-button"
                  :class="noButtonClass"
                  @click="$emit('close')"
          >
            {{ modalNo }}
          </button>
          <button type="button"
                  class="yes-button"
                  :class="yesButtonClass"
                  @click="$emit('confirm')"
          >
            {{ modalYes }}
          </button>
        </template>
      </general-modal>
    </span>
</template>

<script>
import GeneralModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal'

export default {
  name: 'ConfirmationModal',
  components: {
    GeneralModal,
  },
  props: {
    modalTitle: {type: String, default: null},
    modalBody: {type: String, default: null},
    modalYes: {type: String, default: null},
    yesButtonClass: {type: String, default: ''},
    modalNo: {type: String, default: null},
    noButtonClass: {type: String, default: ''},
  },
};
</script>