export default {
	data() {
		return {
			windowWidth: 0,
			gridBp: 'xs'
		}
	},
	mounted() {
    const onResize = () => {
      this.windowWidth = window.document.body.clientWidth
      this.gridBp = 'xxs'
      if (this.windowWidth >= 1700) {
        this.gridBp = 'xxl'
      } else if (this.windowWidth >= 1200) {
        this.gridBp = 'xl'
      } else if (this.windowWidth >= 992) {
        this.gridBp = 'lg'
      } else if (this.windowWidth >= 768) {
        this.gridBp = 'md'
      } else if (this.windowWidth >= 576) {
        this.gridBp = 'sm'
      } else if (this.windowWidth >= 390) {
        this.gridBp = 'xs'
      }
    }
    window.addEventListener("resize", onResize);
    
		onResize();
	}
}
