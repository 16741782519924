<template>
  <div class="progress-bar">
    <div class="progress-indeterminate" v-if="indeterminate || max === 0"></div>
    <div class="progress-value" :style="{width: width}" v-else></div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    max: { type: Number, default: 100 },
    value: { type: Number, default: 50 },
    indeterminate: { type: Boolean, default: false },
  },
  computed: {
    width() {
      if (this.max === 0) {
        return '0%'
      }

      return (this.value / this.max) * 100 + '%'
    }
  }
}
</script>
