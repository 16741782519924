'use strict'

import {
  flattenFields
} from 'paperclip-lib/src/report-configs/ReportConfigUtils'

function extractInputFields (fields) {
  return flattenFields(fields)
    .filter(field => ['input', 'canvas'].includes(field.type))
}

export default {
  computed: {
    inputFields () {
      return extractInputFields(this.reportConfigFields)
    },
    inputKeys () {
      return [
        ...new Set(
          this.inputFields
            .map(field => field?.options?.name),
        )]
    },
    mandatoryFieldKeys () {
      return [
        ...new Set(
          this.inputFields
            .filter(field => field?.options?.required)
            .map(field => field?.options?.name),
        )]
    },
  },
  methods: {
    checkMandatoryFields () {
      return this.mandatoryFieldKeys.every(key => !!this.report.data[key])
    },
  }
}
