<template>
  <div class="statistics-chart">
    <component
        v-if="chartData.datasets"
        :is="chartType"
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
    />
    <ps-accordion-collapse class="border">
      <template #header="{active}">
        <div class="d-flex justify-content-between p-2">
          {{ $t('chart.table', { name: chartConfig.name }) }}
          <i class="fas fa-chevron-left" :class="{active}" aria-hidden="true"></i>
        </div>
      </template>
      <template #content>
        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead>

            <tr>
              <th>{{ datasetIdKey }}</th>
              <th v-for="set in chartConfig.config.datasets" :key="set.label">
                {{ set.label }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(option, idx) in options" :key="option.value">
              <th>{{ option.label }}</th>
              <td v-for="(set, setIdx) in chartConfig.config.datasets" :key="set.label">
                {{ chartData.datasets[setIdx].data[idx] }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
    </ps-accordion-collapse>
  </div>
</template>

<script>

import { v1 } from 'uuid'
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import dayjs from 'dayjs'
import PsAccordionCollapse from 'pixelstein-vue-app-package/src/vue2/PsAccordion/PsAccordionCollapse'
import { applyFilter } from 'paperclip-lib/src/charts/ChartUtils'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'StatisticsChart',
  components: {
    PsAccordionCollapse,
    Bar,
    // , Pie, Bubble, Doughnut, Line, PolarArea, Radar, Scatter
  },
  props: {
    chartConfig: { type: Object, default: () => ({}) },
    data: { type: Array, default: () => ([]) },
    datasetIdKey: { type: String, default: 'label' },
    colors: { type: Array, default: () => (['#244C5A', '#2AB4A8', '#CE4A32']) },
    chartType: { type: String, default: 'bar' },
    width: { type: Number, default: 1700 },
    height: { type: Number, default: 800 },
    cssClasses: { default: '', type: String },
    styles: { type: Object, default: () => ({}) },
    plugins: { type: Array, default: () => [] },
    reportFields: { type: Array, default: () => [] },
  },
  data () {
    return {
      chartId: v1(),
      chart: null,
    }
  },
  computed: {
    chartValueFieldConfig () {
      return this.reportFields.find(
          field => field?.options?.name === this.chartConfig.config.chart_value)
    },
    chartValueFieldType () {
      return this.chartValueFieldConfig?.options?.type
    },
    options () {
      if (['radio', 'checkbox', 'select'].includes(this.chartValueFieldType)) {
        return this.chartValueFieldConfig?.options?.values
      }

      return [
        ...new Set(
            this.data
                .map(report => report.data[this.chartConfig.config.chart_value])
                .filter(value => value !== undefined),
        ),
      ]
          .map(value => ({
            value,
            label: this.getChartValueWithFormat(
                { data: { [this.chartConfig.config.chart_value]: value } },
                this.chartConfig,
            ),
          }))
          .sort((a, b) => `${a.label}`.localeCompare(`${b.label}`))
    },
    chartData () {
      const datasets = this.chartConfig
          .config
          .datasets
          ?.map(datasetConfig => this.buildDataset(datasetConfig))

      return {
        labels: this.options.map(option => option.label),
        datasets,
      }
    },
    chartOptions () {
      return {
        legend: {
          display: this.chartType === 'pie',
        },
        scales: {
          y: {
            display: this.chartType === 'bar',
            beginAtZero: true,
            ticks: {
              min: 0,
              suggestedMax: 8,
              precision: 0,
            },
          },
        },
        responsive: true,
      }
    },
  },
  methods: {
    getChartValueWithFormat (report, chartConfig) {
      const value = report.data[chartConfig.config.chart_value]

      if (this.chartValueFieldType === 'datetime-local') {
        return dayjs(value).format(chartConfig.config.chart_value_format)
      }

      return value
    },
    buildDataset (config) {
      const filteredReports = applyFilter(this.data, config?.data_filter)
      const data = []

      this.options.forEach(option => {
        if (this.chartValueFieldType === 'checkbox') {
          const count = filteredReports.filter(
              report => this.getChartValueWithFormat(report, this.chartConfig)?.[option.value],
          )?.length || 0

          data.push(count)
        } else {
          const count = filteredReports.filter(
              report => this.getChartValueWithFormat(report, this.chartConfig) === option.value,
          )?.length || 0

          data.push(count)
        }
      })

      return {
        label: config.label,
        data,
        backgroundColor: [config.color],
        borderWidth: 1,
        barPercentage: 1,
      }
    },
  },
}
</script>
