<template>
  <div>
    <p>
      {{$t('help.files.description')}}
    </p>
    <p>
      {{$t('help.files.library.description')}}
    </p>
    <ul>
      <li><b>{{$t('help.files.library.sort_big')}}</b> {{$t('help.files.library.sort')}}</li>
      <li><b>{{$t('help.files.library.dir_big')}}</b> {{$t('help.files.library.dir')}}</li>
      <li><b>{{$t('help.files.library.tags_big')}}</b> {{$t('help.files.library.tags')}}</li>
    </ul>
    <div class="mt-4 mb-2 menu" :class="{active: openParagraph === 'upload'}" @click="setParagraph('upload')">
      <svg-icon name="arrow_right"/><h5 class="d-inline mx-2"><b>{{$t('help.files.library.upload.title')}}</b></h5>
    </div>
    <div class="menu-item" :class="{active: openParagraph === 'upload'}">
      <ul>
        <li>{{$t('help.files.library.upload.tab')}}</li>
        <li>{{$t('help.files.library.upload.overview')}}</li>
        <li>{{$t('help.files.library.upload.new')}}</li>
        <li>{{$t('help.files.library.upload.meta')}}</li>
        <ul>
          <li><b>{{$t('help.files.library.upload.data_title_big')}}</b> {{$t('help.files.library.upload.data_title')}}</li>
          <li><b>{{$t('help.files.library.upload.tags_big')}}</b> {{$t('help.files.library.upload.tags')}}</li>
          <li><b>{{$t('help.files.library.upload.dir_big')}}</b> {{$t('help.files.library.upload.dir')}}</li>
        </ul>
        <li>{{$t('help.files.library.upload.save')}}</li>
      </ul>
      <p>{{$t('help.files.overview')}}</p>
    </div>
  </div>
</template>

<script>
import SvgIcon from "paperclip-lib/src/components/SvgIcon";
export default {
  name: 'FilesHelp',
  components: { SvgIcon },
  data() {
    return {
      openParagraph: "",
    }
  },
  methods: {
    setParagraph(string) {
      this.openSubtypes = false
      this.openParagraph === string ? this.openParagraph = '' : this.openParagraph = string
    }
  }
}
</script>