<template>
  <div class="report-module view">
    <search-bar class="my-3"
                :placeholder="$t('reports.search_in_reports')"
                :debounceWait="700"
                v-model="searchQuery"
    />

    <multi-layout-list
        ref="filelist"
        :items="reportConfigs"
        :list-key="'report-module'"
        az-sort-key="name"
        date-sort-key="modified"
        offset-to=".view"
        :initial-scroll-pos="scrollPos"
        :initial-page="page"
        @scroll-to-end="page++"
        :sortingOptions="sortingOptions"
    >
      <template #headerToolbar>

      </template>
      <template #static_masonery_item_before v-if="canCreateReports">

        <router-link to="/reports/config/new">
          <general-card
              :default-image="addReportThumbnail"
              :title="$t('reports.new_report_config')"
          />
        </router-link>

      </template>
      <template #cardTemplate="{item}">
        <general-card
            :default-image="reportThumbnail"
            :title="item.name"
            :subtitle="$tc('reports.report_title', item.reports_count ?? 0, {count: item.reports_count ?? 0})"
            @click="handleItemOption({value:'view'}, item)"
            class="mb-3"
        >
          <div @click.stop>
            <drop-down class="p-2 ml-3"
                       :options="itemOptions"
                       :drop-up="true"
                       :drop-left="shouldDropLeft(item.id)"
                       close-on-choose
                       :force-close="item.id !== dropDownItem"
                       @open="dropDownItem = item.id"
                       @click="handleItemOption($event, item)"
            >
              <svg-icon name="ellipsis"/>
            </drop-down>
          </div>
        </general-card>
      </template>
      <template #static_list_item_before v-if="canCreateReports">
        <general-list-item
            class="mb-2"
            :default-image="addReportThumbnail"
            :title="$t('reports.new_report_config')"
            @click="$router.push('/reports/config/new')"
        />
      </template>
      <template #listItemTemplate="{item}">
        <general-list-item
            :default-image="reportThumbnail"
            :title="item.name"
            :subtitle="$tc('reports.report_title', item.reports_count ?? 0, {count: item.reports_count ?? 0})"
            @click="handleItemOption({value:'view'}, item)"
        >
          <div @click.stop>
            <drop-down class="p-2 ml-3"
                       :options="itemOptions"
                       :drop-up="false"
                       drop-left
                       close-on-choose
                       :force-close="item.id !== dropDownItem"
                       @open="dropDownItem = item.id"
                       @click="handleItemOption($event, item)"
            >
              <svg-icon name="ellipsis"/>
            </drop-down>
          </div>
        </general-list-item>
      </template>
    </multi-layout-list>

    <transition name="fade">
      <router-view></router-view>
    </transition>

    <div class="loading-modal" v-if="loading">
      <loading-screen/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import SearchBar from '@/components/SearchBar'
import SvgIcon from "paperclip-lib/src/components/SvgIcon";
import DropDown from '@/components/DropDown'
import MultiLayoutList from '@/components/MultiLayoutList'
import GeneralCard from '@/components/GeneralCard'
import GeneralListItem from '@/components/GeneralListItem'
import { saveAs } from 'file-saver'
import { ACTION_CREATE, ACTION_UPDATE, hasPermission } from '@/utils/permissions.js'
import addReportThumbnail from '@/assets/defaultThumbnails/add_report.svg'
import reportThumbnail from '@/assets/defaultThumbnails/report.svg'
import LoadingScreen from '@/components/LoadingScreen.vue'

export default {
  name: 'ReportModule',
  components: {
    MultiLayoutList,
    DropDown,
    SvgIcon,
    SearchBar,
    GeneralCard,
    GeneralListItem,
    LoadingScreen
  },
  data () {
    return {
      searchQuery: '',
      lastSearchQuery: '',
      loading: false,
      page: 1,
      itemsPerPage: 20,
      scrollPos: 0,
      addReportThumbnail,
      reportThumbnail,
      dropDownItem: null
    }
  },
  computed: {
    ...mapState({
      allReportConfigs: state => state.Api.ReportConfigs.all,
      user: state => state.user,
    }),
    sortingOptions () {
      return [
        {
          key: 'reports_count',
          label: this.$t('reports.sort_by_count'),
          comparator: (a, b) => a.reports_count - b.reports_count,
        },
      ]
    },
    reportConfigs () {
      if (this.searchQuery !== this.lastSearchQuery) {
        this.loading = true
      }
      const regex = new RegExp(this.searchQuery, 'i')
      this.lastSearchQuery = this.searchQuery;

      const filteredReportConfigs = this.allReportConfigs.filter(con => con.campaign?.match(regex) || con.name.match(regex))
      setTimeout(() => this.loading = false, 1000)
      return filteredReportConfigs
    },
    itemOptions () {
      const options = [
        {
          group: 'default',
          groupLabel: 'default',
          value: 'view',
          label: this.$t('reports.view_reports'),
          active: false,
        },
        {
          group: 'default',
          groupLabel: 'default',
          value: 'download_xlsx',
          label: this.$t('reports.download_as_xlsx'),
          active: false,
        },
        {
          group: 'default',
          groupLabel: 'default',
          value: 'download_zip',
          label: this.$t('reports.download_as_zip'),
          active: false,
        },
        {
          group: 'default',
          groupLabel: 'default',
          value: 'share',
          label: this.$t('reports.share_report'),
          active: false,
        },
      ]

      if (hasPermission(this.user, 'ReportConfigs', ACTION_UPDATE)) {
        options.unshift({
          group: 'default',
          groupLabel: 'default',
          value: 'edit',
          label: this.$t('reports.edit_config'),
          active: false,
        })
      }

      return options
    },
    canCreateReports () {
      return !!hasPermission(this.user, 'ReportConfigs', ACTION_CREATE)
    },
  },
  methods: {
    ...mapActions({
      getReports: 'Api/Reports/index',
      addReportConfig: 'Api/ReportConfigs/add',
      deleteReportConfig: 'Api/ReportConfigs/delete',
    }),
    ...mapMutations({
      resetReportConfigs: 'Api/ReportConfigs/reset',
    }),
    shouldDropLeft(id) {
      if (document.getElementById(id) && document.getElementById(id).offsetLeft < 5) {
        return false;
      }
      return true;
    },
    async handleItemOption (action, reportConfig) {
      switch (action.value) {
        case 'edit':
          this.$router.push(['/reports/config', reportConfig.id].join('/')).catch(() => null)
          break
        case 'view':
          this.$router.push(['/reports', reportConfig.id].join('/')).catch(() => null)
          break
        case 'download_xlsx': {
          const file = await this.getReports({ type: 'xlsx', filter: { report_config_id: reportConfig.id } })

          saveAs(file, reportConfig.name + '.xlsx')
        }
          break
        case 'download_zip': {
          const file = await this.getReports({ type: 'zip', filter: { report_config_id: reportConfig.id } })

          saveAs(file, reportConfig.name + '.zip')
        }
          break
        case 'share':
          await this.shareReport(reportConfig)
          break
      }
    },
    async shareReport (reportConfig) {
      await this.$router.push({ name: 'ReportShareModal', params: { reportConfigId: reportConfig.id } })
    },
  },
  mounted () {
    if (this.$route.name !== 'ReportShareModal' && window.localStorage.getItem("closeShareModal") !== 'true') {
      this.loading = true;
      window.localStorage.setItem("closeShareModal", 'false');
    }
  }
}
</script>

