<template>
  <general-modal dialog-classes="modal-xl edit-entity-modal"
                 :close-on-backdrop="false"
                 :title="modalTitle"
                 @close="$emit('showModal', false)"
  >
    <template #default>
      <div v-for="item in items"
           :key="item.id"
           class="d-flex align-items-center"
      >
        <slot :item="item" :items="items" name="formInputSlot"></slot>
        <div class="flex-shrink-1 pl-2" v-if="allowedDelete">
          <form-checkbox
              :options="[{
            label: $t('edit.delete_button'),
            value: item.id
              }]"
              class="tag delete-button mt-2"
              v-model="checkedOptions"
          >
            <i class="fa-solid fa-trash" aria-hidden="true"></i>
          </form-checkbox>
        </div>
      </div>
      <hr v-if="addNewItem">
      <form v-if="addNewItem" class="d-flex align-items-center"
            @submit.prevent="addItem"
      >
        <slot :newItem="newItem" :items="items" name="formInputAddItemSlot"></slot>
        <div class="flex-shrink-1 pl-2 mt-3">
          <button type="submit"
                  class="btn btn-secondary add-button"
                  :disabled="!canAddDirectory"
          >
            {{ addNewBtnLabel }}
          </button>
        </div>
      </form>
    </template>
    <template #footer>
      <div v-for="val in redundantValues" :key="val" class="text-danger w-100 text-right">
        {{ uniqueInvalidInfo }}
      </div>
      <div v-for="value in emptyValues" :key="value" class="text-danger w-100 text-right">
        {{ requiredInvalidInfo }}
      </div>
      <button type="button" class="btn btn-light mr-5" @click="$emit('showModal', false)">
        {{ cancelBtnLabel }}
      </button>
      <button
          v-if="saveBtnLabel"
          type="button"
          class="btn btn-primary"
          :disabled="emptyValues.length > 0 || redundantValues.length > 0"
          @click="onSave"
      >
        {{ saveBtnLabel }}
      </button>
    </template>
  </general-modal>
</template>

<script>
import GeneralModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal'
import FormCheckbox from '@pixelstein/ps-form/components/PsFormCheckbox'
import { v1 } from 'uuid'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'EditEntityModal',
  components: {
    GeneralModal,
    FormCheckbox,
  },
  props: {
    items: { type: Array, default: () => [] },
    allowedDelete: { type: Boolean, default: true },
    requiredValues: { type: Array, default: () => [] },
    requiredUniqueValues: { type: Array, default: () => [] },
    addNewItem: { type: Boolean, default: false },
    modalTitle: { type: String, default: '' },
    uniqueInvalidInfo: { type: String, default: '' },
    requiredInvalidInfo: { type: String, default: '' },
    addNewBtnLabel: { type: String, default: '' },
    cancelBtnLabel: { type: String, default: '' },
    saveBtnLabel: { type: String, default: '' },
  },
  data () {
    return {
      newItem: {},
      checkedOptions: {}
    }
  },
  computed: {
    canAddDirectory () {
      for (const [key, value] of Object.entries(this.newItem)) {
        if (!this.requiredValues.find(el => el === key) ||
            !this.items.find(item => item[key].trim() === value.trim()) && value.trim() !== '') {
          return true
        }
      }
      return false
    },
    emptyValues () {
      if (!this.requiredValues) {
        return []
      }

      return this.requiredValues
          .filter(value => {
            return this.items
                .filter(item => !item.delete)
                .filter(item => !item[value] || item[value].trim() === '').length > 0
          })
    },
    redundantValues () {
      if (!this.requiredUniqueValues) {
        return []
      }

      return this.requiredUniqueValues
          .filter(value => {
            return this.items
                .some(item => this.items
                    .filter(it => it[value] === item[value]).length > 1)
          })
    },
  },
  methods: {
    onSave() {
      const clone = _cloneDeep(this.items)

      clone.forEach(item => {
        item.delete = !!this.checkedOptions[item.id]
      })

      this.$emit('save', clone)
    },
    addItem () {
      this.newItem.id = v1()
      this.newItem.new = true
      this.$emit('add', this.newItem)
      this.newItem = {}
    },
  },
}
</script>
