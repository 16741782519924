<template>
  <div class="form-content-composer-options-text">
    <form-radio
        :label="$t('form_content_composer.options_text.label.font_size')"
        class="mb-3"
        :options="textSizeOptions"
        v-model="textSize"
    />
    <div class="checkbox-wrap mb-3">
      <form-checkbox
          :options="textStyles"
          :label="$t('form_content_composer.options_text.label.font_style')"
          v-model="textStyle"
      />
    </div>
    <form-input
        :label="$t('form_content_composer.options_text.label.text')"
        :value="value.text"
        @input="onInput('text', $event)"
    />
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'

import FormRadio from '@pixelstein/ps-form/components/PsFormRadio'
import FormCheckbox from '@pixelstein/ps-form/components/PsFormCheckbox'
import FormInput from '@pixelstein/ps-form/components/PsFormInput'

export default {
  name: 'FormContentComposerOptionsText',
  components: {
    FormRadio,
    FormCheckbox,
    FormInput,
  },
  emits: ['set-sub-classes', 'input'],
  props: {
    value: { type: Object, default: () => ({}) },
  },
  data () {
    return {
      textSize: 'normal',
      textStyle: {},
    }
  },
  computed: {
    textSizeOptions () {
      return [
        { value: 'normal', label: this.$t('form_content_composer.text_size_option.normal') },
        { value: 'h1', label: this.$t('form_content_composer.text_size_option.h1') },
        { value: 'h2', label: this.$t('form_content_composer.text_size_option.h2') },
        { value: 'h3', label: this.$t('form_content_composer.text_size_option.h3') },
        { value: 'h4', label: this.$t('form_content_composer.text_size_option.h4') },
        { value: 'h5', label: this.$t('form_content_composer.text_size_option.h5') },
        { value: 'h6', label: this.$t('form_content_composer.text_size_option.h6') },
      ]
    },
    textStyles () {
      return [
        { value: 'font-italic', label: this.$t('form_content_composer.text_style.italic') },
        { value: 'font-weight-bold', label: this.$t('form_content_composer.text_style.bold') },
      ]
    },
  },
  watch: {
    textSize (nv, ov) {
      if (nv !== ov) {
        const textStyles = Object.entries(this.textStyle)
            .filter(entry => entry[1])
            .map(([key]) => key)
            .join(' ')

        this.onUpdateSubClasses([nv, textStyles].join(' '))
      }
    },
    textStyle: {
      deep: true,
      handler (nv) {
        const textStyles = Object.entries(nv)
            .filter(entry => entry[1])
            .map(([key]) => key)
            .join(' ')

        this.onUpdateSubClasses([this.textSize, textStyles].join(' '))
      },
    },
  },
  methods: {
    onUpdateSubClasses (subClasses) {
      this.$emit('set-sub-classes', subClasses)
      this.onInput('classes', subClasses)
    },
    onInput (path, value) {
      const valueClone = _cloneDeep(this.value)

      _set(valueClone, path, value)

      this.$emit('input', valueClone)
    },
  },
  mounted () {
    if (this.value?.classes) {
      const styles = this.value.classes.split(' ')

      this.textSize = styles.shift()
      this.textStyle = styles.map(style => ({ [style]: true }))
          .reduce((accu, val) => ({ ...accu, ...val }))
    }
  },
  beforeDestroy () {
    this.$emit('set-sub-classes', '')
  },
}
</script>
