<template>
  <div class="form-content-composer-options-canvas">

    <form-input
        :label="$t('form_content_composer.options_canvas.label.name')"
        :value="value.name"
        required
        @input="onInput('name', $event)"
    />

    <input hidden :required="keyNotUnique"/>
    <div v-if="keyNotUnique" class="ps-form-partial-input-info mb-3">
      <span class="invalid-note invalid">{{ $t('key_not_unique') }}</span>
    </div>

    <form-input
        :label="$t('form_content_composer.options_canvas.label.label')"
        :value="value.label"
        required
        @input="onInput('label', $event)"
    />

    <form-input
        :label="$t('form_content_composer.options_canvas.label.height')"
        :value="height"
        required
        @input="onInput('height', $event)"
    />

    <form-input
        :label="$t('form_content_composer.options_canvas.label.clear')"
        :value="value.clear"
        required
        @input="onInput('clear', $event)"
    />

    <form-input
        :label="$t('form_content_composer.options_canvas.label.undo')"
        :value="value.undo"
        required
        @input="onInput('undo', $event)"
    />

    <form-input
        :label="$t('form_content_composer.options_canvas.label.unlock')"
        :value="value.unlock"
        required
        @input="onInput('unlock', $event)"
    />

    <form-switch
        :label="$t('form_content_composer.options_input.label.required')"
        :value="value.required"
        @input="onInput('required', $event)"
    />

    <multi-file-upload
        class=" mb-2"
        :button-label-html-first-item="$t('report_editor.image')"
        :allowed-mime-types="allowedMimeTypes"
        :allowed-file-extensions="allowedFileExtensions"
        :max-file-count="1"
        :value="uploadValue"
        @input="uploadFile"
        @validity-change.prevent="$emit('validity-change', $event)"
    />

  </div>
</template>

<script>
import _set from 'lodash/set'
import _cloneDeep from 'lodash/cloneDeep'

import FormInput from '@pixelstein/ps-form/components/PsFormInput'
import FormSwitch from '@pixelstein/ps-form/components/PsFormSwitch'
import MultiFileUpload from '@/components/Reports/MultiFileUpload.vue'

export default {
  name: 'FormContentComposerOptionsCanvas',
  components: { MultiFileUpload, FormInput, FormSwitch },
  props: {
    value: { type: Object, default: () => ({ values: {} }) },
    keyNotUnique: { type: Boolean, default: false },
    allowedMimeTypes: { type: Array, default: () => ['image/png', 'image/jpeg'] },
    allowedFileExtensions: { type: Array, default: () => ['jpg', 'jpeg', 'png'] },
  },
  computed: {
    height () {
      return this.value.height?.replace('px', '') ?? '150'
    },
    uploadValue () {
      if (this.value?.file) {
        return [this.value?.file]
      } else {
        return []
      }
    },
  },
  methods: {
    async uploadFile (newFiles) {
      if (newFiles?.progressingFiles?.length) {
        this.onInput('file', newFiles)
      } else {
        this.onInput('file')
      }
    },
    onInput (path, value) {
      let valueClone = _cloneDeep(this.value)

      if (path === 'height' && value.match(/^\d+$/)) {
        value += 'px'
      }

      _set(valueClone, path, value)

      if (path === 'file' && !value) {
        delete valueClone.file_id
        delete valueClone.options?.svgData
        delete valueClone.svgData
      }

      console.log('onInput valueClone', valueClone)

      this.$emit('input', valueClone)
    },
  },
}
</script>
