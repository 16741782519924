<template>
  <div>
    <p>
      {{$t('help.statistics.description')}}
    </p>
    <p>
      {{$t('help.statistics.individual')}}
    </p>
    <div class="mt-4 mb-2 menu" :class="{active: openParagraph === 'create'}" @click="setParagraph('create')">
      <svg-icon name="arrow_right"/><h5 class="d-inline mx-2"><b>{{$t('help.statistics.create.title')}}</b></h5>
    </div>
    <div class="menu-item" :class="{active: openParagraph === 'create'}">
      <ul>
        <li>{{$t('help.statistics.create.tab')}}</li>
        <li>{{$t('help.statistics.create.list')}}</li>
        <li>{{$t('help.statistics.create.form')}}</li>
        <li>{{$t('help.statistics.create.new')}}</li>
        <li>{{$t('help.statistics.create.add_title')}}</li>
        <li>{{$t('help.statistics.create.other_forms')}}</li>
        <li>{{$t('help.statistics.create.about')}}</li>
        <li>{{$t('help.statistics.create.set')}}</li>
        <li>{{$t('help.statistics.create.set_name')}}</li>
        <li>{{$t('help.statistics.create.filter')}}</li>
        <li>{{$t('help.statistics.create.condition')}}</li>
        <ul>
          <li>{{$t('help.statistics.create.value.is')}}</li>
          <li>{{$t('help.statistics.create.value.is_not')}}</li>
        </ul>
        <li>{{$t('help.statistics.create.add_value')}}</li>
        <li>{{$t('help.statistics.create.preview')}}</li>
        <li>{{$t('help.statistics.create.table_view')}}</li>
        <li>{{$t('help.statistics.create.save')}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import SvgIcon from "paperclip-lib/src/components/SvgIcon";
export default {
  name: 'StatisticsHelp',
  components: { SvgIcon },
  data() {
    return {
      openParagraph: "",
    }
  },
  methods: {
    setParagraph(string) {
      this.openSubtypes = false
      this.openParagraph === string ? this.openParagraph = '' : this.openParagraph = string
    }
  }
}
</script>