<template>
  <div class="message-center-module view scroll pb-3">
    <div class="sticky-top bg-white mx-n3 px-3 pb-1">
      <search-bar class="my-3"
                  :placeholder="$t('message_center.search_placeholder')"
                  :debounceWait="700"
                  v-model="search"
      />

      <h2 class="h3 text-uppercase">{{ $t('message_center.title') }}</h2>
      <p>{{ $t('message_center.last_update', { date: toFormattedDateTime() }) }}</p>
    </div>

    <div ref="messages_row"
         class="message-row row"
         :style="messageRowStyle"
    >
      <div class="message-preview-wrap"
           :class="{'col-sm-12': !selectedMessageId, 'col-sm-4': !!selectedMessageId}"
      >
        <message-preview
            :messages="filteredMessages"
            :value="selectedMessageId"
            @input="openMessage">
          <template #static_message_item_before v-if="canCreateMessage">
            <div class="form-row" @click.prevent="msg = {title: '', 'content:encoded':'', published: '<p></p>'}">
              <div class="col-2 message-button-column">
                <img src="@/assets/defaultThumbnails/add_message.svg"
                     class="img-fluid"
                     alt="">
              </div>
              <div class="col-10 message message-content-column">
                <h5>{{ $t('message_center.add_new_message') }}</h5>
              </div>
            </div>
          </template>
        </message-preview>
      </div>
      <transition name="fade">
        <div v-if="selectedMessage" class="col-sm-8 message-wrap">
          <div class="toolbar d-flex align-content-end">
            <button
                class="btn btn-sm btn-outline-secondary mr-3"
                type="button"
                @click.prevent.stop="msg = selectedMessage"
            >
              <i class="fa-solid fa-edit"></i>
            </button>
            <confirmed-click-button
                btn-class="btn btn-outline-danger btn-sm no-background"
                :modal-title="$t('message_center.safe_delete.title', {title: selectedMessage.title})"
                :modal-body="$t('message_center.safe_delete.body')"
                :modal-yes="$t('message_center.safe_delete.yes')"
                :modal-no="$t('message_center.safe_delete.no')"
                no-button-class="btn btn-light justify-self-start"
                @click="deleteThisMessage(selectedMessage.id)"
                v-if="canDeleteMessages"
            >
              <i class="fa-solid fa-trash"></i>
            </confirmed-click-button>
          </div>
          <div class="text-light pb-2">
            {{ toFormattedDateTime(selectedMessage.created) }}
          </div>
          <h3>{{ selectedMessage.title }}</h3>
          <div v-html="selectedMessage.body"></div>
        </div>
      </transition>
    </div>

    <general-modal v-if="msg"
                   :title="msg.id ? $t('message_center.modal.edit_title') : $t('message_center.add_new_message')"
                   :close-on-backdrop="false"
                   dialog-classes="modal-xl"
                   @close="msg = null">
      <template #default>
        <div class="form-row">
          <div class="col-8">
            <form-input :label="$t('message_center.modal.title_label')"
                        v-model="msg.title"
            />
          </div>
          <div class="col-12">
            <label>{{ $t('message_center.modal.content_label') }}</label>
            <form-tip-tap :heading-levels="[2,3,4]" disable-image v-model="msg.body"/>
          </div>
        </div>
      </template>
      <template #footer>
        <button type="button" class="btn btn-primary" @click.prevent="saveMessage">
          {{ msg.id ? $t('message_center.modal.edit_save_button') : $t('message_center.modal.new_save_button') }}
        </button>
      </template>
    </general-modal>
    <div class="loading-modal" v-if="loading">
      <loading-screen/>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import GeneralModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal'
import FormInput from '@pixelstein/ps-form/components/PsFormInput'
import ConfirmedClickButton from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalButtonConfirmationModal'
import DateFormats from 'pixelstein-vue-app-package/src/mixins/date-formats'
import FormTipTap from '@pixelstein/ps-form/components/PsFormRichTextEditor'
import SearchBar from '@/components/SearchBar'
import MessagePreview from '@/components/MessagePreview'
import LoadingScreen from '@/components/LoadingScreen'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { ACTION_CREATE, ACTION_DELETE, hasPermission } from '@/utils/permissions.js'
import { findTotalOffsets } from 'paperclip-lib/src/dom/DomUtils'

/**
 * @mixes DateFormats
 */
export default {
  name: 'MessageCenterModule',
  mixins: [DateFormats],
  components: {
    MessagePreview,
    SearchBar,
    GeneralModal,
    FormTipTap,
    ConfirmedClickButton,
    FormInput,
    LoadingScreen,
  },
  data () {
    return {
      search: '',
      lastSearchQuery: '',
      selectedMessageId: '',
      messageRowOffset: 0,
      msg: null,
      loading: true,
    }
  },
  computed: {
    ...mapState({
      allMessages: state => state.Api.Messages.all,
      user: state => state.user,
    }),
    ...mapGetters({
      findMessage: 'Api/Messages/find',
    }),
    filteredMessages () {
      if (this.search !== this.lastSearchQuery) {
        this.loading = true
      }
      const regex = new RegExp(this.search, 'i')
      this.lastSearchQuery = this.search;

      const filteredMessages = this.allMessages.filter(msg => !!(msg.body.match(regex) || msg.title.match(regex)))
      setTimeout(() => this.loading = false, 1000)
      return filteredMessages
    },
    selectedMessage () {
      return this.filteredMessages.find(msg => msg.id === this.selectedMessageId)
    },
    messageRowHeight () {
      return window.innerHeight - this.messageRowOffset
    },
    messageRowStyle () {
      return {
        height: `${this.messageRowHeight}px`,
      }
    },
    canCreateMessage () {
      return !!hasPermission(this.user, 'Messages', ACTION_CREATE)
    },
    canDeleteMessages () {
      return !!hasPermission(this.user, 'Messages', ACTION_DELETE)
    }
  },
  methods: {
    ...mapActions({
      getMessages: 'Api/Messages/index',
      addMessage: 'Api/Messages/add',
      editMessage: 'Api/Messages/edit',
      deleteMessage: 'Api/Messages/delete',
    }),
    ...mapMutations({
      resetMessages: 'Api/Messages/reset',
      updateMessage: 'Api/Messages/addOrUpdate',
    }),
    async fetchMessages () {
      this.loading = true

      this.resetMessages()

      try {
        await this.getMessages({
          $ignorePagination: true,
          limit: 100,
        })
      } finally {
        setTimeout(() => this.loading = false, 1000)
      }
    },
    updateMessageRowOffset () {
      if (this.$refs.messages_row) {
        this.messageRowOffset = findTotalOffsets(this.$refs.messages_row).top
      }
    },
    openMessage (message_id) {
      const msg = this.findMessage(message_id)
      const clone = _cloneDeep(msg)
      clone.read = true

      this.updateMessage({ item: clone })
      this.selectedMessageId = message_id
    },
    saveMessage () {
      if (this.msg.id) {
        console.log('message', this.msg)

        this.editMessage({
          id: this.msg.id,
          title: this.msg.title,
          body: this.msg.body,
        })
            .then(() => {
              this.fetchMessages()
              this.msg = null
            })
      } else {
        this.addMessage({
          title: this.msg.title,
          body: this.msg.body,
        })
            .then(() => {
              this.fetchMessages()
              this.msg = null
            })
      }
    },
    deleteThisMessage (id) {
      this.deleteMessage({
        id: id,
      })
    },
  },
  mounted () {
    this.$nextTick(this.updateMessageRowOffset)
    window.addEventListener('resize', this.updateMessageRowOffset)
  },
  created () {
    this.fetchMessages()
  },
}
</script>
