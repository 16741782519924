<template>
  <div class="ps-form-multi-file-upload">
    <form-partial-label
        :label="label"
        :required="required"
        :mandatory-marker="'*'"
    >
      <slot name="label"/>
    </form-partial-label>
    <div class="files-wrap" v-if="mergedFiles.length > 0 ">
      <div v-for="file in mergedFiles"
           :key="file.id"
           @click="showFile = file">
        <div class="file-item">
          <button
              v-if="!disabled"
              type="button"
              class="file-item-remove-button"
              @click.stop="removeFile(file)"
          >
            &times;
          </button>
          <div v-if="cssThumbnails"
               class="file-item-thumbnail-wrap"
               :style="'background-image: url(\''+getThumbnail(file)+'\')'">
          </div>
          <div v-else class="file-item-thumbnail-wrap">
            <img v-if="file.type?.startsWith('image') || file.file?.type?.startsWith('image')"
                 :src="getThumbnail(file)"
                 class="file-item-thumbnail"
                 :alt="file.filename ?? file.name">
            <template v-else>{{ file.filename ?? file.name }}</template>
          </div>
        </div>
        <div class="p-2" v-if="file.progress && file.progress.loaded !== 100">
          <label for="progress">{{ $t('upload_progress') + ' ' + Math.round(file.progress) + '%' }}</label>
          <progress-bar class="upload-progress w-100"
                    id="progress"
                    :max="100"
                    :value="file.progress" />
        </div>
      </div>
    </div>
    <div v-if="mergedFiles.length < maxFileCount && !disabled" class="">
      <label v-for="i in inputCount" :key="'input_'+i" class="file-upload-label">
        <input type="file"
               :required="required && i === 1"
               :multiple="maxFileCount > 1"
               :disabled="disabled"
               @invalid.prevent="$emit('validity-change', false)"
               @valid.prevent="$emit('validity-change', true)"
               @change="addFiles"
               :accept="[...allowedMimeTypes, ...allowedFileExtensions].join()">
        <div v-html="mergedFiles.length > 0 ? buttonLabelHtml:buttonLabelHtmlFirstItem"></div>
      </label>
    </div>
    <ps-modal-general-modal
        v-if="showFile"
        dialog-classes="modal-xl"
        @close="showFile = null"
        :title="$t?$t('multi_file_upload.preview.title'):''"
    >
      <div v-if="isDb(showFile)"
           class="text-center">
        <p><i class="fa-solid fa-database fa-2xl"></i></p>
        <p>{{ showFile.name ?? showFile.filename }}</p>
      </div>
      <div v-else>
        <img :src="typeof showFile.file === 'string' ? showFile.file : getThumbnail(showFile) "
             alt=""
             class="img-fluid shadow"
        >
      </div>

      <div v-if="typeof showFile.file === 'string'" class="text-right pt-3">
        <button class="btn btn-primary" @click="downloadFile(showFile)">
          {{ $t('multi_file_upload.preview.download') }}
        </button>
      </div>

    </ps-modal-general-modal>
  </div>

</template>

<script>

import { v1 } from 'uuid'
import prettyBytes from 'pretty-bytes'

import FormPartialLabel from '@pixelstein/ps-form/components/partials/PsFormPartialLabel.vue'

import PsModalGeneralModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal'
import FormConfiguredContent from '@pixelstein/ps-form/components/PsFormConfiguredContent'
import { fileToDataUrl } from 'paperclip-lib/src/files/FileUtils'
import ProgressBar from '@/components/ProgressBar.vue'

export default {
  name: 'FormMultiFileUpload',
  emits: ['input', 'validity-change'],
  components: {
    ProgressBar,
    PsModalGeneralModal,
    FormConfiguredContent,
    FormPartialLabel,
  },
  props: {
    maxFileSize: { type: Number, default: () => 0 }, // in Byte
    allowedMimeTypes: { type: Array, default: () => ['image/png', 'image/jpeg'] },
    allowedFileExtensions: { type: Array, default: () => [] }, // used for the accept attribute
    maxFileCount: { type: Number, default: 6 },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: '' },
    locale: { type: String, default: 'en' },
    buttonLabelHtml: { type: String, default: '' },
    buttonLabelHtmlFirstItem: { type: String, default: '' },
    cssThumbnails: { type: Boolean, default: false },
    primaryPreviewKey: { type: String, default: 'capacitor_url' },
    resultKeys: { type: Array, default: () => ['file', 'id', 'capacitor_url', 'filesystem_url'] },
    value: { type: Array, default: () => [] },
  },
  data () {
    return {
      inputCount: 1,
      files: [],
      showFile: null,
      loading: false,
    }
  },
  computed: {
    mergedFiles () {
      return [...this.value, ...this.files]
          .filter((file, idx, array) => array.findIndex(f => f.id === file.id) === idx)
    },
    currentFileSizesSum() {
      return this.files.map(f => f.file.size)
          .reduce((previousValue, currentValue) => currentValue + previousValue, 0)
    }
  },
  methods: {
    clear () {
      this.files = []
      this.inputCount = 1
    },
    isValidFileType (file) {
      const fileExt = file.name.split('.').pop()

      const matchedMime = this.allowedMimeTypes.length === 0
          || this.allowedMimeTypes.find(mime => {
            const pattern = mime.replaceAll('*', '.*')
            const regex = new RegExp(pattern, 'i')
            return !!file.type.match(regex)
          })

      const matchedExt = this.allowedFileExtensions.length === 0 ||
          this.allowedFileExtensions
              .map(ext => ext.split('.').pop())
              .includes(fileExt)

      return matchedMime || matchedExt
    },
    isValidFileSize (file) {
      return this.maxFileSize === 0 || file.size < ((this.maxFileSize * .9) - this.currentFileSizesSum)
    },
    async addFiles ({ target: { files } }) {
      let newFiles = []

      if (files.length + this.files.length > this.maxFileCount) {
        this.$toast.open({
          type: 'error',
          message: this.$t('multi_file_upload.errors.max_files_reached', { count: this.maxFileCount }),
          position: this.$config.TOAST_POSITION,
        })

        return
      }

      for (const file of files) {
        if (!this.isValidFileType(file)) {
          this.$toast.open({
            type: 'error',
            message: this.$t('multi_file_upload.errors.mime_type_or_ext', {
              filename: file.name,
              type: file.type,
              ext: '.' + file.name.split('.').pop(),
              allowed: [...this.allowedMimeTypes, ...this.allowedFileExtensions].join(', '),
            }),
            position: this.$config.TOAST_POSITION,
          })

          continue
        }

        if (!this.isValidFileSize(file)) {
          this.$toast.open({
            type: 'error',
            message: this.$t('multi_file_upload.errors.file_size', {
              filename: file.name,
              size: prettyBytes(file.size, { locale: this.locale }),
              maxFilesize: prettyBytes(this.maxFileSize * .9, { locale: this.locale }),
              availableSize: prettyBytes(this.maxFileSize * .9 - this.currentFileSizesSum, { locale: this.locale }),
            }),
            position: this.$config.TOAST_POSITION,
          })

          continue
        }

        const newFile = {
          file: file,
          filesize: prettyBytes(file.size, { locale: this.locale }),
          thumbnail: await fileToDataUrl(file),
          name: file.name,
          format: '',
          id: v1(),
        }

        this.files.push(newFile)
        newFiles.push(newFile)
      }

      this.inputCount++

      this.emitUpdate([...this.value, ...newFiles])
    },
    removeFile (item) {
      const idx = this.files.findIndex(file => file.id === item.id)
      if (idx > -1) {
        this.files.splice(idx, 1)
      }

      const valueItemIdx = this.value.findIndex(file => file.id === item.id)
      if (valueItemIdx > -1) {
        let newValue = this.value.slice()
        newValue.splice(valueItemIdx, 1)

        this.$emit('input', newValue)
      }
    },
    getThumbnail (file) {
      const localThumbnail = this.files.find(f => f.id === file.id)?.thumbnail
      if (localThumbnail) {
        return localThumbnail
      }

      return file.thumbnail || file[this.primaryPreviewKey]
    },
    emitUpdate (files) {
      const onProgress = (idx, progress) => {
        if (this.files[idx]) {
          this.$set(this.files[idx], 'progress', progress)
        }
      }

      const onSuccessCallback = (idx) => {
        if (this.files[idx]) {
          this.$set(this.files[idx], 'progress', 100)
          this.$set(this.files[idx], 'success', true)
        }
      }

      const onErrorCallback = (idx) => {
        if (this.files[idx]) {
          this.$set(this.files[idx], 'success', false)
          this.$set(this.files[idx], 'progress', 0)
        }
      }

      const onFinallyCallback = () => {
        this.loading = false

        if (this.files.every(file => file.success)) {
          this.clear()
        }
      }

      const progressingFiles = files.filter(file => !file.success)

      this.$emit('input', {
        progressingFiles,
        onProgress,
        onSuccessCallback,
        onErrorCallback,
        onFinallyCallback,
      })
    },
    downloadFile (file) {
      fetch(file.file)
          .then(res => res.blob())
          .then(blob => {
            let link = document.createElement('a')
            link.setAttribute('download', file.filename || file.name)
            link.href = window.URL.createObjectURL(blob)
            link.click()
            link.remove()
          })
          .catch(e => console.log(e))
    },
    isDb (file) {
      if (typeof file.file === 'string') {
        return file.file.split('.')[file.file.split('.').length - 1] === ('db' || 'sql')
      } else {
        return file.name.split('.')[file.name.split('.').length - 1] === ('db' || 'sql')
      }

    },
  },
  created () {
    if (!this.$t) {
      this.$t = s => s
    }
  },
}
</script>
