<template>
  <div class="login view row justify-content-center align-items-center">
    <div class="col-11 col-sm-6 col-lg-5 col-xl-4">
      <h2 class="text-uppercase text-center mb-5">{{ $t('login.login') }}</h2>
      <form @submit="tryLogin">
        <input
            ref="username"
            autocomplete="username"
            v-model="username"
            :placeholder="$t('login.username')"
            class="mb-4 form-control"
        />
        <input
            v-model="password"
            autocomplete="current-password"
            :placeholder="$t('login.password')"
            type="password"
            class="form-control mb-4 "
        />
        <button class="btn btn-primary btn-block" type="submit">{{ $t('login.login') }}</button>
      </form>
      <div class="small mt-3">
        <router-link :to="{name: 'ForgotPassword'}">{{ $t('login.forgot_password') }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import LoginOptions from 'pixelstein-vue-app-package/src/mixins/login-options'
import { handleDelegatedLogin } from '@/utils/loginDelegation.js'

/**
 * @mixes LoginOptions
 */
export default {
  name: 'Login',
  mixins: [LoginOptions],
  props: {
    successRedirect: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      username: '',
      password: '',
    }
  },
  computed: {
    ...mapState({
      authToken: state => state.Api.authToken,
    }),
  },
  methods: {
    ...mapMutations({
      setUser: 'setUser',
    }),
    ...mapActions({
      login: 'Api/Users/login',
      updateAuthToken: 'Api/updateAuthToken',
      getUser: 'Api/Users/view',
    }),
    async tryLogin (e) {
      e.preventDefault()

      await this.login({
        username: this.username,
        password: this.password,
        identifier: this.getDeviceUuidOption(),
        platform: this.getDevicePlatformOption(),
        push_token: '-',
        app_id: this.getBundleIdOption(),
      })

      this.setUser(await this.getUser({ id: 'self' }))

      await this.redirect()
    },
    async redirect () {
      if (this.successRedirect) {
        const successRoute = this.$config.BTOA_BACK_ROUTE_ENCODING
            ? atob(this.successRedirect)
            : decodeURIComponent(this.successRedirect)

        await this.$router.push(successRoute).catch(() => null)
      } else {
        await this.$router.push('/').catch(() => null)
      }
    },
  },
}
</script>
