<template>
  <div>
    <p>
      {{$t('help.settings.description')}}
    </p>
    <div class="mt-4 mb-2 menu" :class="{active: openParagraph === 'language'}" @click="setParagraph('language')">
      <svg-icon name="arrow_right"/><h5 class="d-inline mx-2"><b>{{$t('help.settings.language.title')}}</b></h5>
    </div>
    <div class="menu-item" :class="{active: openParagraph === 'language'}">
      <p>{{$t('help.settings.language.description')}}</p>
    </div>
    <div class="mt-4 mb-2 menu" :class="{active: openParagraph === 'licence'}" @click="setParagraph('licence')">
      <svg-icon name="arrow_right"/><h5 class="d-inline mx-2"><b>{{$t('help.settings.licence.title')}}</b></h5>
    </div>
    <div class="menu-item" :class="{active: openParagraph === 'licence'}">
      <p>{{$t('help.settings.licence.description')}}</p>
      <ul>
        <li><b>{{$t('help.settings.licence.info.title')}}</b>{{$t('help.settings.licence.info.description')}}</li>
        <li><b>{{$t('help.settings.licence.lic.title')}}</b>{{$t('help.settings.licence.lic.description')}}</li>
        <li><b>{{$t('help.settings.licence.qr.title')}}</b>{{$t('help.settings.licence.qr.description')}}</li>
      </ul>
    </div>
    <div class="mt-4 mb-2 menu" :class="{active: openParagraph === 'apps'}" @click="setParagraph('apps')">
      <svg-icon name="arrow_right"/><h5 class="d-inline mx-2"><b>{{$t('help.settings.apps.title')}}</b></h5>
    </div>
    <div class="menu-item" :class="{active: openParagraph === 'apps'}">
      <p>{{$t('help.settings.apps.description')}}</p>
    </div>
    <div class="mt-4 mb-2 menu" :class="{active: openParagraph === 'css'}" @click="setParagraph('css')">
      <svg-icon name="arrow_right"/><h5 class="d-inline mx-2"><b>{{$t('help.settings.css.title')}}</b></h5>
    </div>
    <div class="menu-item" :class="{active: openParagraph === 'css'}">
      <p>{{$t('help.settings.css.description')}}</p>
    </div>
    <div class="mt-4 mb-2 menu" :class="{active: openParagraph === 'information'}" @click="setParagraph('information')">
      <svg-icon name="arrow_right"/><h5 class="d-inline mx-2"><b>{{$t('help.settings.information.title')}}</b></h5>
    </div>
    <div class="menu-item" :class="{active: openParagraph === 'information'}">
      <p>{{$t('help.settings.information.description')}}</p>
    </div>
  </div>
</template>

<script>
import SvgIcon from "paperclip-lib/src/components/SvgIcon";
export default {
  name: 'SettingsHelp',
  components: { SvgIcon },
  data() {
    return {
      openParagraph: "",
    }
  },
  methods: {
    setParagraph(string) {
      this.openSubtypes = false
      this.openParagraph === string ? this.openParagraph = '' : this.openParagraph = string
    }
  }
}
</script>