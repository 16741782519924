<template>
  <div class="list-picker">
    <label v-if="label">{{ label }}</label>

    <div class="item-wrap">
      <button v-for="item in value"
              :key="item.id"
              :title="removeButtonTitle"
              @click="remove(item)"
      >
        <span class="icon-wrap">
          <span class="hover-icon">
            <i class="fas fa-times" aria-hidden="true"></i>
          </span><span class="prefix" v-html="itemPrefix"></span></span>{{ item.name }}
      </button>
    </div>

    <div class="add-wrap mt-3">
      <div class="input-group mb-3">
        <input type="text"
               class="form-control"
               :placeholder="searchPlaceholder"
               v-model.trim="query"
        >
        <div class="input-group-append">
          <button type="button"
                  class="btn btn-outline-secondary"
                  :disabled="loading || !canCreate"
                  @click="create"
          >
            {{ createButtonText }}
          </button>
        </div>
      </div>

      <div class="item-wrap">
        <button v-for="item in filteredAll"
                :key="item.id"
                class="tag-button"
                :title="addButtonTitle"
                @click="add(item)"
        >
        <span class="icon-wrap">
          <span class="hover-icon">
            <i class="fas fa-plus" aria-hidden="true"></i>
          </span><span class="prefix" v-html="itemPrefix"></span></span>{{ item.name }}
        </button>
      </div>

    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'ListPicker',
  props: {
    all: { type: Array, default: () => [] },
    value: { type: Array, default: () => [] },
    label: { type: String, default: '' },
    removeButtonTitle: { type: String, default: 'remove' },
    addButtonTitle: { type: String, default: 'add' },
    createButtonText: { type: String, default: 'create' },
    searchPlaceholder: { type: String, default: 'find..' },
    itemPrefix: { type: String, default: '' },
  },
  data () {
    return {
      query: '',
      loading: false,
    }
  },
  computed: {
    filteredAll () {
      if (this.query.trim().length === 0) {
        return []
      }

      const regex = new RegExp(this.query, 'i')
      return this.all
          .filter(tag => !this.value.find(selectedTag => selectedTag.id === tag.id)) // all unselected tags
          .filter(tag => tag.name.match(regex)) // all unselected tags matching query
    },
    canCreate () {
      return !this.all.find(tag => tag.name.trim() === this.query.trim())
    },
  },
  methods: {
    remove (value) {
      const index = this.value.findIndex(v => v.id === value.id)
      const newValue = this.value.slice()
      newValue.splice(index, 1)

      this.$emit('input', newValue)
    },
    add (tag) {
      const newValues = _cloneDeep(this.value)
      newValues.push(tag)

      this.$emit('input', newValues)
    },
    create () {
      if (this.loading) {
        return
      }
      this.loading = true

      const onSuccessCallback = (value) => {
        this.add(value)
      }

      const onFinallyCallback = () => {
        this.query = ''
        this.loading = false
      }

      this.$emit('create', {
        name: this.query,
        onSuccessCallback,
        onFinallyCallback,
      })
    },
  },
}
</script>

<style scoped>

</style>
