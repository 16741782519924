<template>
  <div class="login view row justify-content-center align-items-center">
    <div class="col-11 col-sm-6 col-lg-5 col-xl-4">
      <h2 class="text-uppercase text-center mb-5">{{ $t('reset_password.reset_password') }}</h2>
      <form @submit.prevent="send">
        <input
            type="text"
            autocomplete="username"
            v-model="username"
            :placeholder="$t('login.username')"
            minlength="12"
            class="mb-4 form-control"
        />
        <input
            type="password"
            autocomplete="new-password"
            v-model="password"
            :placeholder="$t('reset_password.new_password')"
            minlength="12"
            class="mb-4 form-control"
        />
        <button class="btn btn-primary btn-block" type="submit">{{ $t('reset_password.send') }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import LoginOptions from 'pixelstein-vue-app-package/src/mixins/login-options'
import { mapActions } from 'vuex'

/**
 * @mixes LoginOptions
 */
export default {
  name: 'ResetPassword',
  mixins: [LoginOptions],
  props: {
    token: String
  },
  data() {
    return {
      username: null,
      password: null,
    }
  },
  methods: {
    ...mapActions({
      resetPassword: 'Api/Users/reset-password'
    }),
    async send() {
      try {
        await this.resetPassword({
          token: this.token,
          username: this.username,
          password: this.password,
          ...this.loginOptions,
        })

        this.$router.push('/')
      } catch (error) {
        this.$toast.error(
            this.$t('reset_password.errors.not_valid'),
            { position: this.$config.TOAST_POSITION },
        )

        this.$router.push({ name: 'Login' })
      }
    }
  }
}
</script>
