<template>
  <div class="statistics-list view">
    <search-bar class="my-3"
                :placeholder="$t('statistics_list.search')"
                :debounceWait="700"
                v-model="searchQuery"
    />

    <multi-layout-list v-if="reportConfig" ref="filelist"
                       :items="filteredCharts"
                       az-sort-key="name"
                       date-sort-key="modified"
                       offset-to=".view"
                       :initial-scroll-pos="scrollPos"
                       :initial-page="page"
                       @scroll-to-end="page++"
    >
      <template #headerToolbar>
        <router-link-back to="/statistics">
          {{ reportConfig.name }}
        </router-link-back>
      </template>
      <template #static_masonery_item_before>
        <general-card
            :title="$t('statistics_list.add_new')"
            :default-image="addStatisticThumbnail"
            @click="handleItemOption({value:'new'})"
        />
      </template>
      <template #static_list_item_before>
        <general-list-item
            :title="$t('statistics_list.add_new')"
            :default-image="addStatisticThumbnail"
            @click="handleItemOption({value:'new'})"
        />
      </template>
      <template #cardTemplate="{item}">
        <general-card
            :default-image="statisticThumbnail"
            :title="item.name"
            @click="handleItemOption({value:'view'}, item)"
            class="mb-3"
        >
          <div @click.stop>
            <drop-down class="p-2 ml-3"
                       :options="itemOptions"
                       :drop-up="true"
                       :drop-left="shouldDropLeft(item.id)"
                       close-on-choose
                       :force-close="item.id !== dropDownItem"
                       @open="dropDownItem = item.id"
                       @click="handleItemOption($event, item)"
            >
              <svg-icon name="ellipsis"/>
            </drop-down>
          </div>
        </general-card>
      </template>
      <template #listItemTemplate="{item}">
        <general-list-item
            :default-image="statisticThumbnail"
            :title="item.name"
            @click="handleItemOption({value:'view'}, item)"
        >
          <div @click.stop>
            <drop-down class="p-2 ml-3"
                       :options="itemOptions"
                       :drop-up="false"
                       drop-left
                       close-on-choose
                       :force-close="item.id !== dropDownItem"
                       @open="dropDownItem = item.id"
                       @click="handleItemOption($event, item)"
            >
              <svg-icon name="ellipsis"/>
            </drop-down>
          </div>
        </general-list-item>
      </template>
    </multi-layout-list>

    <confirmation-modal v-if="statisticToDelete"
                        :modal-title="$t('statistics_list.safe_delete.title', {statisticname: statisticToDelete.name})"
                        :modal-body="$t('statistics_list.safe_delete.body')"
                        :modal-no="$t('statistics_list.safe_delete.no')"
                        :modal-yes="$t('statistics_list.safe_delete.yes')"
                        :noButtonClass="'btn btn-light justify-self-start'"
                        @close="statisticToDelete = null"
                        @confirm="tryDeleteStatistic(statisticToDelete)"
    />

    <div class="loading-modal" v-if="loading">
      <loading-screen/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import SearchBar from '@/components/SearchBar'
import SvgIcon from "paperclip-lib/src/components/SvgIcon";
import DropDown from '@/components/DropDown'
import MultiLayoutList from '@/components/MultiLayoutList'
import GeneralCard from '@/components/GeneralCard'
import GeneralListItem from '@/components/GeneralListItem'
import RouterLinkBack from '@/components/RouterLinkBack'
import LoadingScreen from '@/components/LoadingScreen'
import addStatisticThumbnail from '@/assets/defaultThumbnails/add_statistic.svg'
import statisticThumbnail from '@/assets/defaultThumbnails/statistic.svg'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

export default {
  name: 'StatisticsList',
  components: {
    ConfirmationModal,
    LoadingScreen,
    MultiLayoutList,
    DropDown,
    SvgIcon,
    SearchBar,
    GeneralCard,
    GeneralListItem,
    RouterLinkBack,
  },
  props: {
    reportConfigId: { type: String, default: null },
  },
  data () {
    return {
      searchQuery: '',
      lastSearchQuery: '',
      loading: false,
      page: 1,
      itemsPerPage: 20,
      scrollPos: 0,
      addStatisticThumbnail,
      statisticThumbnail,
      dropDownItem: null,
      statisticToDelete: null
    }
  },
  computed: {
    ...mapState({
      charts: state => state.Api.Charts.all,
      reportConfig: state => state.Api.ReportConfigs.current,
    }),
    filteredCharts () {
      if (this.searchQuery !== this.lastSearchQuery) {
        this.loading = true
      }
      const regex = new RegExp(this.searchQuery, 'i')
      this.lastSearchQuery = this.searchQuery;

      const filteredCharts = this.charts
          .filter(chart => chart)
          .filter(chart => !!chart.report_configs?.find(rc => rc.id === this.reportConfigId))
          .filter(chart => chart.name.match(regex))
      setTimeout(() => this.loading = false, 1000)
      return filteredCharts
    },
    itemOptions () {
      return [
        {
          group: 'default',
          groupLabel: 'default',
          value: 'view',
          label: this.$t('statistics_list.view'),
          active: false,
        },
        {
          group: 'default',
          groupLabel: 'default',
          value: 'edit',
          label: this.$t('statistics_list.edit'),
          active: false,
        },
        {
          group: 'default',
          groupLabel: 'default',
          value: 'delete',
          label: this.$t('statistics_list.delete'),
          active: false,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getReportConfig: 'Api/ReportConfigs/view',
    }),
    ...mapActions({
      deleteChart: 'Api/Charts/delete',
    }),
    shouldDropLeft(id) {
      if (document.getElementById(id) && document.getElementById(id).offsetLeft < 5) {
        return false;
      }
      return true;
    },
    async fetchReportConfig () {
      await this.getReportConfig({
        id: this.reportConfigId,
        contain: 'charts',
      })
    },
    handleItemOption (action, chart) {
      switch (action.value) {
        case 'new':
          this.$router.push(['/statistics', this.reportConfigId, 'new'].join('/')).catch(() => null)
          break
        case 'view':
          this.$router.push(['/statistics', this.reportConfigId, 'view', chart.id].join('/')).catch(() => null)
          break
        case 'edit':
          this.$router.push(['/statistics', this.reportConfigId, 'edit', chart.id].join('/')).catch(() => null)
          break
        case 'delete':
          this.statisticToDelete = chart
      }
    },
    tryDeleteStatistic (chart) {

      this.deleteChart({
        id: chart.id,
      })

      this.statisticToDelete = null;
    }
  },
  async mounted () {
    try {
      const handle = setTimeout(() => this.loading = true, 250)

      await this.fetchReportConfig()

      clearTimeout(handle)
    } catch (e) {
      this.apiErrorHandler(e)
    } finally {
      this.loading = false
    }
  },
}
</script>

