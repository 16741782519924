<template>
  <div class="form-list-column-composer">
    <template v-for="field in selectedFields">
      <div class="list-column border-light border-bottom mb-2 p-2">
        <div class="d-flex align-items-center justify-content-between">
          {{ $t('form_content_composer.list_column.field_label', { field: field.options.label }) }}
          <button type="button" class="btn btn-outline-danger btn-sm" @click="removeListField(field)">
            <i class="fas fa-trash" aria-hidden="true"></i>
          </button>
        </div>

        <form-radio
            :label="$t('form_content_composer.list_column.width_label')"
            :name="field.options.name"
            :options="columnOptions"
            :value="field.list_column.class.split(' ').find(c => c.startsWith('col'))"
            @input="updateColumnClass(field, $event)"
        />
      </div>
    </template>

    <div class="form-row pt-4 align-items-center">
      <div class="col-9 col-xl-10">
        <form-select
            :label="$t('form_content_composer.list_column.add_column_label')"
            option-value-key="key"
            option-label-key="value"
            :options="selectableFields"
            v-model="selected"
        />
      </div>
      <div class="col-3 col-xl-2">
        <button type="button"
                class="btn btn-secondary btn-block px-1 mt-3"
                @click="addListField"
        >
          <i class="fas fa-plus" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'

import FormSelect from '@pixelstein/ps-form/components/PsFormSelect'
import FormRadio from '@pixelstein/ps-form/components/PsFormRadio'
import { flattenFields, getFieldPath } from 'paperclip-lib/src/report-configs/ReportConfigUtils'

export default {
  name: 'FormListColumnComposer',
  components: {
    FormSelect,
    FormRadio,
  },
  props: {
    value: { type: Array, default: () => [] },
  },
  data () {
    return {
      selected: '',
      columnOptions: [
        // {value: 'col-1', label: this.$t('form_content_composer.column_options.col-1')},
        // {value: 'col-2', label: this.$t('form_content_composer.column_options.col-2')},
        { value: 'col-3', label: this.$t('form_content_composer.column_options.col-3') },
        { value: 'col-4', label: this.$t('form_content_composer.column_options.col-4') },
        // {value: 'col-5', label: this.$t('form_content_composer.column_options.col-5')},
        { value: 'col-6', label: this.$t('form_content_composer.column_options.col-6') },
        // {value: 'col-7', label: this.$t('form_content_composer.column_options.col-7')},
        { value: 'col-8', label: this.$t('form_content_composer.column_options.col-8') },
        { value: 'col-9', label: this.$t('form_content_composer.column_options.col-9') },
        // {value: 'col-10', label: this.$t('form_content_composer.column_options.col-10')},
        // {value: 'col-11', label: this.$t('form_content_composer.column_options.col-11')},
        { value: 'col-12', label: this.$t('form_content_composer.column_options.col-12') },
      ]
    }
  },
  computed: {
    flatFields () {
      return flattenFields(this.value)
          .filter(field => field.type === 'input' && field.options.type !== 'file')
    },
    filteredFlatFields () {
      return this.flatFields
          .filter((field, idx, fields) => fields.findIndex(f => f.options.name === field.options.name) === idx)
    },
    selectableFields () {
      return this.filteredFlatFields
          .map(field => ({ value: field.options.label, key: field.options.name }))
    },
    selectedFields () {
      return this.flatFields
          .filter(field => !!field.list_column)
          .slice()
          .sort((a, b) => a.list_column.sorting - b.list_column.sorting)
    },
  },
  methods: {
    addListField () {
      const selectedField = _cloneDeep(this.filteredFlatFields.find(field => field?.options?.name === this.selected))
      selectedField.list_column = { class: 'col-12', sorting: this.selectedFields.length + 1, useToSort: false }

      const path = getFieldPath(this.value, selectedField.id)

      this.onInput(path, selectedField)
    },
    removeListField (field) {
      const clone = _cloneDeep(field)

      delete clone.list_column

      const path = getFieldPath(this.value, clone.id)

      this.onInput(path, clone)
    },
    onInput (path, value) {
      let valueClone = _cloneDeep(this.value)
      _set(valueClone, path, value)

      this.$emit('input', valueClone)
    },
    updateColumnClass (field, col) {
      const itemIdx = this.value.findIndex(f => f.id === field.id)

      this.onInput([itemIdx, 'list_column', 'class'], col)
    },
  },
}
</script>
