'use strict'

import 'pixelstein-vue-app-package/src/update-checker'

import Vue from 'vue'
import router from '@/router'
import store from '@/store'

import RuntimeConfigPlugin
  from '@pixelstein/runtime-config-plugin/dist/RuntimeConfigPlugin'
import i18n from '@/i18n'

import lineClamp from 'vue-line-clamp'

import ApiHelper from 'pixelstein-vue-app-package/src/mixins/api-helper'
import ErrorFormatter from '@/utils/errorFormatter.js'
import { isNavigationFailure } from 'vue-router'
import { handleDelegatedLogin } from '@/utils/loginDelegation.js'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(isoWeek)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(advancedFormat)

Vue.config.productionTip = false

export default async function (rootComponent) {
  Vue.use(lineClamp)
  
  Vue.use(VueToast)
  
  Vue.mixin(ApiHelper)
  
  const configPlugin = new RuntimeConfigPlugin('/', process.env)
  const config = await configPlugin.load(process.env.NODE_ENV)
  
  const toastOptions = {
    position: config.TOAST_POSITION,
    dismissible: true,
    pauseOnHover: true,
    duration: 5000,
  }
  
  Vue.use(configPlugin)
  
  i18n.locale = window.localStorage.getItem('language') || navigator.language ||
    navigator.userLanguage
  
  store.commit('Api/setApiBaseUrl', config.API_BASE_URL)
  
  const token = await checkAuthToken(await handleDelegatedLogin())
  
  if (token) {
    try {
      store.commit(
        'setUser',
        await store.dispatch('Api/Users/view', { id: 'self' }),
      )
    } catch (err) {
    }
  }
  
  async function checkAuthToken (token) {
    const storageKey = process.env.VUE_APP_AUTH_TOKEN_STORAGE_KEY ??
      'VUE_APP_AUTH_TOKEN_STORAGE_KEY'
    const storedToken = token ?? window.localStorage.getItem(storageKey)
    
    try {
      await store.dispatch('Api/updateAuthToken', storedToken)
    } catch (e) {
      return null
    }
    
    return storedToken
  }
  
  function conditionalRedirect (to, from) {
    if (config.PUBLIC_ACCESS) {
      console.log('Public path, no redirect', to?.path)
      return null
    }
    
    if (to) {
      if (
        to?.meta?.requiresAuth
        && !to.path?.match?.(/(enroll)|(reset_password)|(login)|(config)/gi)
      ) {
        console.log('Private path', to.path)
        
        return buildReturnRoute(to.path)
      }
    } else {
      if (from?.meta?.requiresAuth) {
        console.log('Private path (current)', from?.path)
        
        return buildReturnRoute(from?.path)
      }
    }
    
    return null
  }
  
  function buildReturnRoute (path) {
    const loginPath = config.LOGIN_PATH || '/login'
    
    if (!path) {
      return loginPath
    }
    
    const encodedBackRoute = config.BTOA_BACK_ROUTE_ENCODING
      ? btoa(path)
      : encodeURIComponent(path)
    
    return [loginPath, encodedBackRoute].join('/')
  }
  
  window.addEventListener('focus', async () => {
    const token = await checkAuthToken()
    
    if (!token) {
      const redirect = conditionalRedirect(router.currentRoute)
      
      if (redirect) {
        router.push(redirect).catch(() => null)
      }
    }
  })
  
  // check if the auth token is still valid
  router.beforeEach((to, from, next) => {
    checkAuthToken()
      .then(valid => {
        if (!valid) {
          const redirect = conditionalRedirect(to, from)
          
          if (redirect) {
            next(redirect)
            
            return
          }
        }
        
        next()
      })
      .catch(() => null)
  })
  
  // check if the current user has permission to navigate to "to"
  router.beforeEach((to, from, next) => {
    store.dispatch('isRouteAllowed', to).then(allowed => {
      if (!allowed) {
        allowed = conditionalRedirect(to, from) ?? buildReturnRoute(to.path)
      }
      
      next(allowed)
    })
  })
  
  router.onError(err => {
    console.log('router error', err)
  })
  
  const app = new Vue({
    router,
    store,
    i18n,
    render: h => h(rootComponent),
  })
  
  const errorFormatter = new ErrorFormatter(
    (message, options) => app.$t(message, options))
  
  Vue.config.errorHandler = (err, instance, info) => {
    if (isNavigationFailure(err)) {
      return
    }
    
    if (err?.code === 401) {
      store.commit(
        'setUser',
        null,
      )
      store.commit('Api/clearAuthToken')
      
      const redirect = conditionalRedirect(router.currentRoute)
      if (redirect) {
        router.push(redirect).catch(() => null)
      }
      
      return
    }
    
    console.error(err)
    
    app.$toast?.error(
      errorFormatter.format(err),
      toastOptions,
    )
  }
  
  window.addEventListener('unhandledrejection', function (event) {
    Vue.config.errorHandler(event.reason)
  })
  
  return app
}
