<template>
  <div class="list-style-control">
    <a v-for="option in options"
       :key="option.type"
       href="#"
       :class="{active: option.type === value}"
       @click.prevent.stop="$emit('input', option.type)"
    >
      <svg-icon :name="option.icon"/>
    </a>
  </div>
</template>

<script>
import SvgIcon from "paperclip-lib/src/components/SvgIcon";

export default {
  name: "ListStyleControl",
  components: {
    SvgIcon,
  },
  props: {
    options: {type: Array, default: () => []},
    value: {type: String, default: ''}
  },

}
</script>

<style scoped>

</style>
