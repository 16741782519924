<template>
  <div class="scroll-container h-100">
    <div ref="toolbar"
         class="toolbar-row"
         :class="{'sticky-top': stickyToolbar}"
         :style="'top: '+stickyToolbar?topOffset:0+'px !important'">
      <div class="col">
        <slot name="toolbar"/>
      </div>
    </div>

    <div ref="scroll_wrap"
         class="list-scroll-wrap"
         :style="'height: '+scrollWrapHeight+'px !important'"
         @scroll="onScroll">
      <slot/>
    </div>
  </div>
</template>

<script>
import { findTotalOffsets } from 'paperclip-lib/src/dom/DomUtils'

export default {
  name: 'ScrollContainer',
  data () {
    return {
      topOffset: 0,
      toolbarHeight: 0,
      parentHeight: 0,
    }
  },
  props: {
    stickyToolbar: { type: Boolean, default: true },
    offsetTo: {type: String, default: 'html'}, // selector
  },
  computed: {
    scrollWrapHeight () {
      return this.parentHeight - (this.topOffset + this.toolbarHeight)
    },
  },
  methods: {
    setTopOffset () {
      this.$nextTick(() => {
        this.topOffset = findTotalOffsets(this.$el, this.offsetTo).top
        this.toolbarHeight = this.$refs?.toolbar?.clientHeight || 0
        this.parentHeight = this.$el?.parentElement?.clientHeight || 0
      })
    },
    onScroll ({ target }) {
      this.$emit('scroll', target.scrollTop)

      const scrollTopMax = target.scrollHeight - target.clientHeight
      if (target.scrollTop > scrollTopMax - this.scrollThreshold) {
        this.$emit('scroll-to-end')
      }
    },
  },
  mounted () {
    this.$nextTick(this.setTopOffset)

    this.resizeObserver = new window.ResizeObserver(this.setTopOffset)
    this.resizeObserver.observe(this.$el.parentElement)
  },
  beforeDestroy () {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  },
}
</script>
