<template>
  <div class="tag-filter" :class="{active}" :style="{height}">

    <div ref="tag_wrap" class="tag-wrap">
      <form-checkbox
          :options="tagOptions"
          class="tag mb-1"
          :value="checkedTags"
          @input="setActive"
      />
    </div>
    <transition name="fade">
      <button v-if="overload"
              type="button"
              class="filter-toggle"

              @click="active = !active"
      >
        <svg-icon class="min-svg" name="arrow_down"/>
      </button>
    </transition>

  </div>
</template>

<script>

import FormCheckbox from '@pixelstein/ps-form/components/PsFormCheckbox'
import SvgIcon from "paperclip-lib/src/components/SvgIcon";
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'TagFilter',
  components: {
    FormCheckbox,
    SvgIcon,
  },
  props: {
    tags: { type: Array, default: () => [] },
    value: { type: Array, default: () => [] },
  },
  data () {
    return {
      wrapHeight: 'auto',
      tagHeight: 'auto',
      active: false,
      overload: false,
    }
  },
  computed: {
    tagOptions () {
      return this.tags
          .map(tag => ({ label: tag.name, value: tag.id }))
    },
    checkedTags () {
      return this.value
          .map(tag => ({ [tag.id]: true }))
          .reduce((accu, config) => ({ ...accu, ...config }), {})
    },
    height () {
      return this.active
          ? `${this.wrapHeight}px`
          : `${this.tagHeight}px`

    },
  },
  watch: {
    tags () {
      this.$nextTick(this.setOverload)
    },
    active (nv) {
      if (!nv) {
        this.$refs
            .tag_wrap
            .scroll({
              y: 0,
              x: 0,
              behavior: 'smooth',
            })
      }
    },
  },
  methods: {
    setOverload () {
      this.wrapHeight = this.$refs.tag_wrap.clientHeight
      this.tagHeight = this.$refs.tag_wrap.childNodes[0].firstElementChild?.firstElementChild?.clientHeight
      const overloadMinHeight = this.tagHeight * 1.5

      this.overload = this.wrapHeight > overloadMinHeight
    },
    setActive (value) {
      const clone = _cloneDeep(this.tags
          .filter(tag => value[tag.id]))

      this.$emit('input', clone)
    },
  },
  mounted () {
    this.$nextTick(this.setOverload)
    window.addEventListener('resize', this.setOverload)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setOverload)
  },
}
</script>
