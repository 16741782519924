<template>
  <div id="app" class="container-fluid">
    <div class="top-navigation row" v-if="!embedded">
      <div class="col">
        <div class="logo-wrap">
          <svg-icon name="paperclip" class="logo"/>
          <div class="logo-text">
            <span class="logo-text-inner">
              {{ $t('app_name') }}
              <span class="small" v-if="user">{{ $t('app_name_sub') }}</span>
            </span>
          </div>
        </div>
      </div>
      <div v-if="authToken" class="col text-right">
        <!-- Top Toolbar -->
        <router-link to="/logout">
          {{ $t('navigation.top.logout') }}
        </router-link>
        <a :href="'mailto:'+$config.SUPPORT_EMAIL">
          {{ $t('navigation.top.support') }}
        </a>
      </div>
    </div>
    <div class="row main-row" :class="{embedded:embedded}">
      <div v-if="showModuleNavigation"
           class="module-navigation-column"
           :class="navigationClasses">
        <module-navigation :items="menu" @toggle-menu="toggleMenu"/>
      </div>
      <main :class="mainClasses">
        <router-view :key="$route.path"/>
      </main>
    </div>
  </div>
</template>

<script>
import ModuleNavigation from '@/components/ModuleNavigation'
import SvgIcon from "paperclip-lib/src/components/SvgIcon";
import { mapActions, mapMutations, mapState } from 'vuex'
import { ACTION_READ, hasAllPermissions, hasPermission, PERMISSION_SELF } from '@/utils/permissions.js'

export default {
  name: 'App',
  components: { SvgIcon, ModuleNavigation },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      authToken: state => state.Api.authToken,
      user: state => state.user,
      minimized: state => state.menuMinimized,
      embedded: state => state.embedded,
    }),
    navigationClasses () {
      if (this.embedded) {
        return 'd-none'
      }

      if (this.minimized) {
        return 'col flex-grow-0 minimized'
      } else {
        return 'col-3 col-lg-2'
      }
    },
    mainClasses () {
      if (this.embedded) {
        return 'col-12'
      }

      if (this.minimized) {
        return 'col'
      } else {
        return 'col-9 col-lg-10'
      }
    },
    menu () {
      return this.$router.getRoutes()
          .filter(route => route?.meta?.menu)
          .filter(route => route.meta.requiresAuth && this.user || !route.meta.requiresAuth)
          .filter(route => route.meta.roles && this.user.role === route.meta.roles || !route.meta.roles)
          .filter(route => hasAllPermissions(this.user, route.meta.requiredPermissions))
          .toSorted((a, b) => a.meta.menu.position - b.meta.menu.position)
    },
    showModuleNavigation () {
      return this.authToken && !this.embedded
    },
  },
  methods: {
    ...mapActions({
      getUser: 'Api/Users/view',
      isRouteAllowed: 'isRouteAllowed',
    }),
    ...mapMutations({
      setUser: 'setUser',
      setMenuMinimized: 'setMenuMinimized',
      setEmbedded: 'setEmbedded',
    }),
    async fetchData () {
      // Format => module: options
      const models = {
        'Api/FileReferences': {
          contain: ['tags', 'files', 'directories'],
        },
        'Api/Tags': {},
        'Api/ReportConfigs': {},
        'Api/Directories': {},
        'Api/Devices': {
          contain: ['users'],
        },
        'Api/Charts': {
          contain: ['report_configs'],
        },
      }

      this.setUser(await this.getUser({ id: 'self' }))

      // Update "all" States of defined modules
      for (const [model, options] of Object.entries(models)) {
        const modelName = model.split('/').pop()
        const permission = hasPermission(this.user, modelName, ACTION_READ)

        if (!permission) {
          continue
        }

        const payload = {
          $ignorePagination: true,
          limit: 100,
          ...options,
        }

        if (permission === PERMISSION_SELF) {
          payload.filter = {
            user_id: this.user.id,
          }
        }

        await this.$store.dispatch(model + '/index', payload, { root: true })
      }

      return true
    },
    toggleMenu () {
      this.setMenuMinimized(!this.minimized)
    },
  },
  async created () {
    const urlParams = new URLSearchParams(location.search)

    this.setEmbedded(urlParams.has('embedded'))

    if (this.authToken) {
      await this.fetchData()
    } else {
      this.setUser(null)
    }

    this.$store.subscribe(async (mutation) => {
      if (mutation.type === 'Api/setAuthToken') {
        if (mutation.payload !== null) {
          await this.fetchData()
        } else {
          this.setUser(null)
        }
      }
    })

    this.$router.afterEach((to, from) => {
      if (to.meta?.title) {
        document.title = this.$t(to.meta.title)
      }
    })

    if (this.$route.meta?.title) {
      document.title = this.$t(this.$route.meta?.title)
    }
  },
}
</script>

<style lang="scss">
@import "styles/main";
</style>
