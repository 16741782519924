<template>
  <div class="multi-file-upload">

    <label class="upload-label">
      <input type="file" multiple @change="addFiles" :accept="mimeAndUrlTypes">
      <template v-if="!$slots.button">
        <svg-icon name="plus"/>
        <span class="text-dark ml-2">{{ $t('multi_file_upload.add_new_files') }}</span>
      </template>
      <template v-else>
        <slot name="button"/>
      </template>
    </label>

    <general-modal v-if="files.length > 0"
                   dialog-classes="modal-xl"
                   :title="$t('multi_file_upload.new_files_modal.title')"
                   :close-on-backdrop="false"
                   @close="clear"
                   :show-close-btn="!loading"

    >
      <template #default>
        <sortable-list :items="files" uniqueKey="id" default-sort-key="">
          <template #header>
            <div class="row">
              <sortable-list-column-header class="col-8">
                {{ $t('multi_file_upload.new_files_modal.filename') }}
              </sortable-list-column-header>
              <sortable-list-column-header class="col-2">
                {{ $t('multi_file_upload.new_files_modal.filesize') }}
              </sortable-list-column-header>
            </div>
          </template>
          <template #itemTemplate="{item}">
            <ps-collapse initial-active>
              <template #header="{active}">
                <div class="form-row bg-extra-light py-1 px-2 align-items-center">
                  <div class="col-8">
                    <i :class="{active}" class="icon fa-solid fa-chevron-right" aria-hidden="true"></i>
                    {{ item.fileReference?.name }}
                  </div>
                  <div class="col-2">
                    {{ item.filesize }}
                  </div>
                  <div class="col-2 text-right">
                    <progress class="upload-progress w-100"
                              v-if="item.progress && item.progress.loaded !== 100"
                              :max="100"
                              :value="item.progress"></progress>
                    <button v-else
                            type="button"
                            class="btn btn-outline-danger px-2 py-1 ml-2"
                            @click.stop="removeFile(item)">
                      <i class="fa-solid fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </template>

              <template #content>
                <div class="p-2 border border-extra-light">
                  <div class="alert alert-warning mb-2" role="alert" v-if="item.warnings">{{ item.warnings }}</div>
                  <file-meta-editor
                      :tags="tags"
                      :directories="directories"
                      v-model="item.fileReference"
                      @create-tag="$emit('create-tag', $event)"
                      @create-directory="$emit('create-directory', $event)"
                  />
                </div>
              </template>
            </ps-collapse>
          </template>
        </sortable-list>

        <label class="upload-label">
          <input type="file" multiple @change="addFiles">
          <svg-icon name="plus"/>
          <span class="text-dark ml-2">{{ $t('multi_file_upload.new_files_modal.add_more_files') }}</span>
        </label>

      </template>
      <template #footer>
        <button type="button" class="btn btn-primary" :disabled="loading" @click.prevent="onUpload">
          <img v-if="loading" src="@/assets/loading_white.svg" alt="loading" style="max-width: 1em"/>
          {{ $t('multi_file_upload.new_files_modal.upload_button') }}
        </button>
      </template>
    </general-modal>
  </div>
</template>

<script>

import {v1} from "uuid";
import prettyBytes from 'pretty-bytes';

import GeneralModal from "pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal";
import SortableList from "pixelstein-vue-app-package/src/vue2/PsSortableList/PsSortableList";
import SortableListColumnHeader from "pixelstein-vue-app-package/src/vue2/PsSortableList/PsSortableListColumnHeader";
import PsCollapse from "pixelstein-vue-app-package/src/vue2/PsAccordion/PsAccordionCollapse";
import SvgIcon from "paperclip-lib/src/components/SvgIcon";
import FileMetaEditor from "@/components/Files/FileMetaEditor";

export default {
  name: "MultiFileUpload",
  components: {
    FileMetaEditor,
    SvgIcon,
    GeneralModal,
    SortableList,
    SortableListColumnHeader,
    PsCollapse,
  },
  props: {
    maxFileSize: {type: Number, default: 1}, //in Byte
    allowedMimeTypes: {type: Array, default: () => []},
    allowedTypeEndings: {type: Array, default: () => []},
    selectedDirectory: {type: Object, default: () => null},
    directories: {type: Array, default: () => []},
    tags: {type: Array, default: () => []},
    value: {type: Object, default: () => ({})}
  },
  data() {
    return {
      inputCount: 1,
      files: [],
      loading: false
    }
  },
  computed: {
    mimeAndUrlTypes() {
      return this.allowedMimeTypes.join() + ', ' + this.allowedTypeEndings.join()
    },
    currentFileSizesSum() {
      return this.files.map(f => f.file.size)
          .reduce((previousValue, currentValue) => currentValue + previousValue, 0)
    }
  },
  methods: {
    isValidFileType(file) {
      const fileExt = file.name.split('.').pop()

      const matchedMime = this.allowedMimeTypes?.length === 0
          || this.allowedMimeTypes
              ?.find(mime => {
            const pattern = mime.replaceAll('*', '.*')
            const regex = new RegExp(pattern, 'i')
            return !!file.type.match(regex)
          })

      const matchedExt = this.allowedFileExtensions?.length === 0 ||
          this.allowedFileExtensions
              ?.map(ext => ext.split('.').pop())
              ?.includes(fileExt)

      return matchedMime || matchedExt
    },
    isValidFileSize(file) {
      return this.maxFileSize === 0 || file.size < ((this.maxFileSize * .9) - this.currentFileSizesSum)
    },
    async addFiles({target: {files}}) {
      for (const file of files) {
        const fileExt = file.name.split('.').pop()

        if (!this.isValidFileType(file)) {
          this.$toast.open({
            type: "error",
            message: this.$t('multi_file_upload.errors.mime_type', {
              filename: file.name,
              type: file.type || '.' + fileExt,
              allowedMimeTypes: this.allowedMimeTypes.join(', '),
              allowedTypeEndings: this.allowedTypeEndings.join(', ')
            }),
            position: this.$config.TOAST_POSITION
          });

          continue;
        }

        if (!this.isValidFileSize(file)) {
          this.$toast.open({
            type: "error",
            message: this.$t('multi_file_upload.errors.file_size', {
              filename: file.name,
              size: prettyBytes(file.size, {locale: this.$i18n.locale}),
              maxFilesize: prettyBytes(this.maxFileSize, {locale: this.$i18n.locale}),
              availableSize: prettyBytes(this.maxFileSize * .9 - this.currentFileSizesSum, { locale: this.locale }),
            }),
            position: this.$config.TOAST_POSITION
          });

          continue;
        }

        const directories = []
        if (this.selectedDirectory) {
          directories.push(this.selectedDirectory)
        }

        this.files.push({
          file: file,
          filesize: prettyBytes(file.size, {locale: this.$i18n.locale}),
          fileReference: {
            name: file.name,
            tags: [],
            directories
          },
          success: null,
          progress: 0,
          id: v1(),
          warnings: await this.checkFile(file)
        })
      }

      this.inputCount++
    },
    removeFile(item) {
      const idx = this.files.findIndex(file => file.id === item.id);
      this.files.splice(idx, 1);
    },
    async checkFile(file) {
      if (file.size > 250*1024*1024) {
        return this.$t('multi_file_upload.warnings.file_size', {filename: file.name, size: prettyBytes(file.size, {locale: this.$i18n.locale})})
      }

      if (file.type.includes('video/')) {
        const video = document.createElement('video')

        video.src = URL.createObjectURL(file)

        const dimension = await (new Promise((resolve) => {
          video.addEventListener('loadedmetadata', function () {
            resolve({
              width: this.videoWidth,
              height: this.videoHeight
            })
          })
        }))

        if (dimension.width > 1280 || dimension.height > 720) {
          return this.$t('multi_file_upload.warnings.resolution', {filename: file.name})
        }
      }

      return null
    },
    onUpload() {
      if (this.loading) {
        return
      }
      this.loading = true;

      const onProgress = (idx, progress) => {
        this.$set(this.files[idx], 'progress', progress);
      }
      const onSuccessCallback = (idx) => {
        this.$set(this.files[idx], 'success', true)
      }
      const onErrorCallback = (idx) => {
        this.$set(this.files[idx], 'success', false);
        this.$set(this.files[idx], 'progress', 0);
      }
      const onFinallyCallback = () => {
        this.loading = false
        if (this.files.every(file => file.success)) {
          this.clear()
        }

      }

      const files = this.files.filter(file => !file.success)

      this.$emit('upload-file', {
        files,
        onProgress,
        onSuccessCallback,
        onErrorCallback,
        onFinallyCallback,
      })
    },
    clear() {
      this.files = [];
      this.inputCount = 1
    }
  }
}
</script>
