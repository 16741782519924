<template>
  <div class="settings-module view scroll bg-extra-light border border-white">
    <div class="mb-5">
      <h2 class="my-3">{{ $t('settings.heading') }}</h2>

      <form-radio
          class="tag"
          :label="$t('settings.locale.label')"
          required
          name="locale"
          :options="locales"
          :value="$i18n.locale"
          @input="updateLocale"
      />
    </div>

    <div class="mb-5">
      <h4>{{ $t('settings.licence_info.licence_key') }}</h4>

      <div class="bg-white">
        <div v-for="lic in licences" :key="lic.id" ref="licences" class="file-list-item bg-transparent form-row">
          <div class="col-11 file-content-column px-3" @click="openLicence(lic)">
            <h5>{{ $t('settings.licence_info.licence_of', { customer: lic.payload.customer_name }) }}</h5>
            <span class="small pr-3">{{
                $t('settings.licence_info.validity', {
                  licence_start: formattedDate(lic.start),
                  licence_end: lic.end ? formattedDate(lic.end) : '-',
                })
              }}</span>
            <span class="small pr-3">{{
                $t('settings.licence_info.installs_available', { installs_available: lic.installs_available })
              }}</span>
            <span class="small pr-3">{{
                $t('settings.licence_info.installed', { installs_count: lic.installs_count })
              }}</span>
          </div>
          <div class="col-1 px-3 text-right">
            <div @click.stop>
              <drop-down
                  class="ml-3"
                  :options="itemOptions"
                  :drop-up="false"
                  drop-left
                  close-on-choose
                  @click="handleItemOption($event, lic)"
              >
                <svg-icon name="ellipsis"/>
              </drop-down>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-5">
      <h4>{{ $t('settings.apps.heading') }}</h4>

      <div class="d-flex justify-content-start" style="gap: 1rem">
        <div v-for="app in apps">
          <a :href="app.link" target="_blank">
            <img :src="app.badge" :alt="app.alt" class="img-fluid">
          </a>
        </div>
      </div>
    </div>

    <div class="mb-5">
      <h4>{{ $t('settings.custom_css.header') }}</h4>

      <ps-form-textarea
          v-model="customCss"
          :maxLength="65535"
          class="custom-css"
          :label="$t('settings.custom_css.label')"
      ></ps-form-textarea>

      <button type="button" class="btn btn-primary" @click.prevent="onSaveCss">{{ $t('generic.buttons.save') }}</button>
    </div>

    <div class="mb-5">
      <div class="text-muted" @click="startDebugMode">
        <h4>{{ $t('settings.info.title') }}</h4>

        {{ $t('settings.info.app_version', { version }) }}
        <br>
        {{ $t('settings.info.user', { username }) }}
        <div class="mb-2 mt-3">
          {{
            $t('settings.fileSize.maxUploadFileSize',
                { prettifiedFileSize: prettifiedSize(sizeLimits.max_upload_file_size) })
          }}
          <br>
          {{ $t('settings.fileSize.maxUploadsPerRequest', { uploadNr: sizeLimits.max_uploads_per_request }) }}
        </div>
        {{ $t('settings.fileSize.maxPostSize', { prettifiedPostSize: prettifiedSize(sizeLimits.max_post_size) }) }}
        <div class="my-2">
          {{ $t('settings.fileSize.maxStorage', { prettifiedStorageSize: prettifiedSize(sizeLimits.max_storage) }) }}
          <br>
          {{ $t('settings.fileSize.usedStorage', { prettifiedUsedSize: prettifiedSize(sizeLimits.used_storage) }) }}
        </div>
      </div>
    </div>

    <general-modal v-if="modalLicence.licenceKey"
                   :title="$t('settings.licence_info.licence_of', {customer: modalLicence.payload.customer_name})"
                   dialog-classes="modal-xl"
                   @close="modalLicence = {}">
      <template #default>
        <div class="form-row justify-content-center">
          <div class="col-12 col-lg-6 text-center" ref="qrcode"></div>
          <div class="col-12 col-lg-6 text-center">
            <div class="pb-3 text-left">
              <h5>{{ $t('settings.licence_info.licence_key') }}</h5>
              <div class="break-word"> {{ modalLicence.licenceKey }}</div>
            </div>
            <button class="btn btn-primary m-2" @click="downloadBlob(modalLicence)">
              {{ $t('settings.licence_info.download_as_lic') }}
            </button>
            <button class="btn btn-primary m-2" @click="downloadQR(modalLicence)">
              {{ $t('settings.licence_info.download_as_qr') }}
            </button>
          </div>
        </div>
      </template>
    </general-modal>

  </div>
</template>

<script>
import dayjs from 'dayjs'
import * as jose from 'jose'
import prettyBytes from 'pretty-bytes'
import FormRadio from '@pixelstein/ps-form/components/PsFormRadio'
import { mapActions, mapState } from 'vuex'
import DropDown from '@/components/DropDown'
import SvgIcon from "paperclip-lib/src/components/SvgIcon";
import GeneralModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal'
import QRious from 'qrious/dist/qrious'
import DebugMode from '@/mixins/debug-mode'
import BadgeiOSen from '@/assets/Download_on_the_App_Store_Badge_EN.svg'
import BadgeiOSde from '@/assets/Download_on_the_App_Store_Badge_DE.svg'
import PsAccordionCollapse from 'pixelstein-vue-app-package/src/vue2/PsAccordion/PsAccordionCollapse'
import PsFormTextarea from '@pixelstein/ps-form/components/PsFormTextarea'

const BADGES = {
  ios: {
    de: BadgeiOSde,
    en: BadgeiOSen,
  },
}

export default {
  name: 'SettingsModule',
  mixins: [DebugMode],
  components: {
    FormRadio,
    GeneralModal,
    DropDown,
    SvgIcon,
    PsAccordionCollapse,
    PsFormTextarea,
  },
  data () {
    return {
      customCssOption: {
        name: 'custom_form_css',
        public: true,
      },
      customCss: '',
      sizeLimits: {},
      currentLicence: 'NIY',
      version: '0.9.15',
      modalLicence: {},
      licences: [],
      blobs: {},
      installs_available: 0,
      installs_count: 0,
      apps: [
        {
          badge: BADGES.ios[this.$i18n.locale] ?? BADGES.ios[this.$i18n.fallbackLocale] ?? BadgeiOSen,
          link: 'https://apps.apple.com/de/app/paperclip/id1644118380',
          alt: '',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      tokenData: state => state.Api.authTokenData,
    }),
    username () {
      return this.user?.username
    },
    token () {
      if (!this.tokenData) {
        return '-'
      }
      return this.tokenData.sub
    },
    locales () {
      return Object.keys(this.$i18n.messages).map(locale => ({
        value: locale,
        label: this.getLocalizedLanguageName(locale),
      })).sort((a, b) => a.label.localeCompare(b.label))
    },
    itemOptions () {
      return [
        {
          group: 'default',
          groupLabel: 'default',
          value: 'open',
          label: this.$t('settings.licence_info.open'),
          active: false,
        },
        {
          group: 'default',
          groupLabel: 'default',
          value: 'download_lic',
          label: this.$t('settings.licence_info.download_as_lic'),
          active: false,
        },
        {
          group: 'default',
          groupLabel: 'default',
          value: 'download_qr',
          label: this.$t('settings.licence_info.download_as_qr'),
          active: false,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      allLicences: 'Api/Instance/licences',
      licence: 'Api/Instance/licence',
      limits: 'Api/Instance/limits',
      user: 'Api/Users/view',
      addOption: 'Api/Options/add',
      updateOption: 'Api/Options/edit',
      options: 'Api/Options/index',
    }),
    async onSaveCss () {
      this.$set(this.customCssOption, 'value', this.customCss)

      if (this.customCssOption.id) {
        this.customCssOption = {
          ...this.customCssOption,
          ...await this.updateOption(this.customCssOption),
        }
      } else {
        this.customCssOption = {
          ...this.customCssOption,
          ...await this.addOption(this.customCssOption),
        }
      }
    },
    async getLicence () {
      let licences = await this.allLicences()

      for (let licence of licences) {
        const licBlob = await this.licence({
          id: licence.id,
          type: 'lic',
        })

        this.blobs[licence.id] = licBlob
        licence.licenceKey = await licBlob.text()

        licence.payload = jose.decodeJwt(licence.licenceKey)
      }

      this.licences = licences
    },
    async getOptions () {
      const result = this.options()
      const customCss = result?.find?.(option => option.name === 'custom_form_css') ?? ''

      if (customCss) {
        this.customCssOption = customCss
        this.customCss = customCss.value
      }
    },
    async getLimits () {
      this.sizeLimits = this.limits()
    },
    handleItemOption (action, item) {
      switch (action.value) {
        case 'open':
          this.openLicence(item)
          break
        case 'download_lic':
          this.downloadBlob(item)
          break
        case 'download_qr':
          this.downloadQR(item)
          break
      }
    },
    openLicence (item) {
      this.modalLicence = item
      const qrCode = this.generateQrCode(item.licenceKey)
      this.$nextTick(() => {
        this.$refs.qrcode.appendChild(qrCode)
      })
    },
    formattedDate (date) {
      return dayjs(date).locale('de').format('DD.MM.YYYY HH:mm')
    },
    generateQrCode (licence) {
      const canvas = document.createElement('canvas')
      new QRious({
        element: canvas,
        value: licence,
        background: '#ffffff',
        backgroundAlpha: 1,
        foreground: '#244C5A',
        foregroundAlpha: 1,
        level: 'H',
        padding: 0,
        size: 300,
      })
      return canvas
    },
    downloadBlob (item) {
      let link = document.createElement('a')
      link.setAttribute('download', 'paperclip_licence_key_' + item.id + '.lic')
      link.href = window.URL.createObjectURL(this.blobs[item.id])
      link.click()
      link.remove()
    },
    downloadQR (item) {
      const qrCode = this.generateQrCode(item.licenceKey).toDataURL()
      const blob = this.DataURIToBlob(qrCode)
      let link = document.createElement('a')
      link.setAttribute('download', 'paperclip_licence_key_' + item.id + '.png')
      link.href = window.URL.createObjectURL(blob)
      link.click()
      link.remove()
    },
    DataURIToBlob (dataURI) {
      const splitDataURI = dataURI.split(',')
      const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
      const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

      const ia = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      return new Blob([ia], { type: mimeString })
    },
    prettifiedSize (size) {
      if (!size) {
        return '-'
      }
      return prettyBytes(size, { locale: this.$i18n.locale })
    },
    updateLocale (locale) {
      this.$i18n.locale = locale
      window.localStorage.setItem('language', locale)
    },
    getLocalizedLanguageName (locale) {
      const locales = {
        de: this.$t('language.de'),
        en: this.$t('language.en'),
        es: this.$t('language.es'),
      }

      return locales[locale] || locale
    },
  },
  async created () {
    await this.getLicence()
    await this.getLimits()
    await this.getOptions()
  },
}
</script>
