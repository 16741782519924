<template>
  <div class="files-module view">

    <search-bar
        class="my-3"
        :placeholder="$t('files.search_in_files')"
        :debounceWait="700"
        v-model="searchQuery"
    />

    <file-directory-menu
        class="my-3"
        :directories="directories"
        v-model="selectedDirectoryId"
        :readonly="!canEditDirectories"
        @edit="onOpenDirectoryModal"
    />

    <multi-layout-list v-if="mappedFileReferences"
                       ref="filelist"
                       :items="mappedFileReferences"
                       :list-key="'file-module'"
                       az-sort-key="title"
                       date-sort-key="modified"
                       offset-to=".view"
                       :initial-page="page"
                       :initial-scroll-pos="scrollPos"
                       @scroll-to-end="page++"
    >
      <template #headerToolbar>
        <div class="my-2">
          <form-checkbox
              :options="fileTypeOptions"
              class="text"
              :value="checkedFileTypes"
              @input="setMimeGroupActive"
          />
        </div>

        <tag-filter
            :tags="filteredTagsOfSelectedDirectory"
            :value="filteredTagsOfSelectedDirectory.filter(({id}) => activeTags[id])"
            @input="updateActiveTags"
        />
      </template>

      <template #static_masonery_item_before v-if="canUploadFiles">
        <multi-file-upload
            v-if="canUploadFiles"
            class="w-100"
            :tags="tags"
            :selected-directory="selectedDirectory"
            :directories="directories"
            :max-file-size="maxUploadFileSize"
            :max-uploads-per-request="maxUploadsPerRequest"
            :allowed-mime-types="$config.UPLOAD_ALLOWED_MIME_TYPES"
            :allowed-type-endings="$config.UPLOAD_ALLOWED_TYPE_ENDINGS"
            @create-tag="createTag"
            @create-directory="onCreateDirectory"
            @upload-file="uploadFile"
        >
          <template #button>
            <div class="file-card">
              <img src="@/assets/defaultThumbnails/add_file.svg" class="card-img-top" alt="add new file"/>
              <div class="card-body">
                <h5 class="card-title">{{ $t('multi_file_upload.add_new_files') }}</h5>
                <p class="card-text">
                </p>
              </div>
            </div>
          </template>
        </multi-file-upload>
      </template>
      <template #cardTemplate="{item}">
        <div @click="handleItemOption({value:'open'}, item)">
          <general-card v-if="!$slots.cardTemplate"
                        :image="item?.file?.thumbnail?$config.API_BASE_URL+item?.file?.thumbnail: null"
                        :default-image="item.default_thumbnail"
                        :title="item?.name"
                        :subtitle="item?.file?.filename"
                        :tags="item.tags"
                        class="mb-3"
          >
            <div @click.stop>
              <drop-down class="p-2 ml-3"
                         :options="itemOptions"
                         :drop-up="true"
                         :drop-left="shouldDropLeft(item.id)"
                         close-on-choose
                         :force-close="item.id !== dropDownItem"
                         @open="dropDownItem = item.id"
                         @click="handleItemOption($event, item)"
              >
                <svg-icon name="ellipsis"/>
              </drop-down>
            </div>
          </general-card>
        </div>
      </template>

      <template #static_list_item_before v-if="canUploadFiles">
        <multi-file-upload
            class="w-100 mb-2"
            :tags="tags"
            :selected-directory="selectedDirectory"
            :directories="directories"
            :max-file-size="maxUploadFileSize"
            :max-uploads-per-request="maxUploadsPerRequest"
            :allowed-mime-types="$config.UPLOAD_ALLOWED_MIME_TYPES"
            :allowed-type-endings="$config.UPLOAD_ALLOWED_TYPE_ENDINGS"
            @create-tag="createTag"
            @create-directory="onCreateDirectory"
            @upload-file="uploadFile"
        >
          <template #button>
            <general-list-item
                :image="addFileThumbnail"
                :title="$t('multi_file_upload.add_new_files')">
            </general-list-item>
          </template>
        </multi-file-upload>

      </template>
      <template #listItemTemplate="{item}">
        <div @click="handleItemOption({value:'open'}, item)">
          <general-list-item
              :image="item?.file?.thumbnail?$config.API_BASE_URL+item?.file?.thumbnail: null"
              :default-image="item.default_thumbnail"
              :title="item?.name"
              :subtitle="item?.file?.filename"
              :tags="item.tags"
          >
            <div @click.stop>
              <drop-down class="p-2 ml-3"
                         :options="itemOptions"
                         :drop-up="false"
                         drop-left
                         close-on-choose
                         :force-close="item.id !== dropDownItem"
                         @open="dropDownItem = item.id"
                         @click="handleItemOption($event, item)"
              >
                <svg-icon name="ellipsis"/>
              </drop-down>
            </div>
          </general-list-item>
        </div>
      </template>
    </multi-layout-list>

    <general-modal v-if="fileReferenceToEdit"
                   dialog-classes="modal-xl"
                   :close-on-backdrop="false"
                   :title="$t('files.edit.header', {filename: fileReferenceToEdit.original_name})"
                   @close="fileReferenceToEdit = null"
    >
      <template #default>
        <file-meta-editor
            :tags="tags"
            :directories="directories"
            v-model="fileReferenceToEdit"
            @create-tag="createTag"
            @create-directory="onCreateDirectory"
        />
      </template>
      <template #footer>
        <span @click.stop class="written-delete-span">
            <confirmed-click-button
                btn-class="btn btn-outline-danger btn-sm no-background written-delete"
                :modal-title="$t('files.safe_delete.title', {filename: fileReferenceToEdit.title})"
                :modal-body="$t('files.safe_delete.body')"
                :modal-yes="$t('files.safe_delete.yes')"
                :modal-no="$t('files.safe_delete.no')"
                no-button-class="btn btn-light justify-self-start"
                @click="tryDeleteFileReference(fileReferenceToEdit)"
            >

              <i class="fa-solid fa-trash" aria-hidden="true"></i>
              {{ $t('directory_control.safe_delete.yes') }}
            </confirmed-click-button>
        </span>
        <button type="button" class="btn btn-light" @click="fileReferenceToEdit = null">
          {{ $t('files.edit.cancel_button') }}
        </button>
        <button v-if="fileReferenceToEdit"
                type="button"
                class="btn btn-primary"
                @click="tryEditFileReference(fileReferenceToEdit)">
          {{ $t('files.edit.save_button') }}
        </button>
      </template>
    </general-modal>

    <confirmation-modal v-if="fileReferenceToDelete"
                        :modal-title="$t('files.safe_delete.title', {filename: fileReferenceToDelete.title})"
                        :modal-body="$t('files.safe_delete.body')"
                        :modal-no="$t('files.safe_delete.no')"
                        :modal-yes="$t('files.safe_delete.yes')"
                        :noButtonClass="'btn btn-light justify-self-start'"
                        @close="fileReferenceToDelete = null"
                        @confirm="tryDeleteFileReference(fileReferenceToDelete)"
    />

    <edit-entity-modal v-if="showDirectoryModal"
                       :modal-title="$t('directory_control.edit_directory')"
                       :unique-invalid-info="$t('directory_control.directory_name_must_be_unique')"
                       :required-invalid-info="$t('directory_control.directories_requires_names')"
                       :add-new-btn-label="$t('directory_control.edit.add_directory_button')"
                       :cancel-btn-label="$t('directory_control.edit.cancel_button')"
                       :save-btn-label="$t('directory_control.edit.save_button')"
                       :items="directories"
                       :required-values="['name']"
                       :required-unique-values="['name']"
                       :add-new-item="true"
                       @showModal="showDirectoryModal = $event"
                       @save="onSaveDirectories"
                       @add="onCreateDirectory"
    >
      <template #formInputSlot="{item, items}">
        <div class="flex-grow-1">
          <form-input
              :label="$t('directory_control.edit.add_directory_placeholder')"
              required
              :invalid-note="$t('directory_control.directory_name_invalid')"
              v-model="item.name"
              :max-length="100"
          />
          <div class="text-warning small mt-n3"
               :class="{invisible: (!items.find(dir => dir.name.trim() === item.name?.trim() && dir.id !== item.id) || item.name?.trim().length === 0)}"
          >
            {{ $t('directory_control.new.name_already_in_use') }}
          </div>
        </div>
      </template>
      <template #formInputAddItemSlot="{newItem, items}">
        <div class="flex-grow-1">
          <form-input
              :label="$t('directory_control.new.directory_label')"
              required
              :invalid-note="$t('directory_control.directory_name_invalid')"
              v-model="newItem.name"
              :max-length="100"
          />
          <div class="text-warning small mt-n3"
               :class="{invisible: (!items.find(dir => dir.name.trim() === newItem.name?.trim()) || newItem.name?.trim().length === 0)}"
          >
            {{ $t('directory_control.new.name_already_in_use') }}
          </div>
        </div>
      </template>
    </edit-entity-modal>

    <div class="loading-modal" v-if="loading">
      <loading-screen/>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'

import { mapActions, mapMutations, mapState } from 'vuex'

import SearchBar from '@/components/SearchBar'
import FileDirectoryMenu from '@/components/Files/FileDirectoryMenu'
import MultiFileUpload from '@/components/Files/MultiFileUpload'
import SvgIcon from 'paperclip-lib/src/components/SvgIcon'
import DropDown from '@/components/DropDown'
import MultiLayoutList from '@/components/MultiLayoutList'
import FileMetaEditor from '@/components/Files/FileMetaEditor'
import FormCheckbox from '@pixelstein/ps-form/components/PsFormCheckbox'
import GeneralModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal'
import ConfirmedClickButton from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalButtonConfirmationModal'

import GeneralCard from '@/components/GeneralCard'
import GeneralListItem from '@/components/GeneralListItem'

import FileTypes from '@/mixins/file-types'
import ViewDataCache from '@/mixins/view-data-cache'
import TagFilter from '@/components/TagFilter'

import { ACTION_CREATE, ACTION_DELETE, ACTION_UPDATE, hasPermission } from '@/utils/permissions.js'
import addFileThumbnail from '@/assets/defaultThumbnails/add_file.svg'
import LoadingScreen from '@/components/LoadingScreen.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import EditEntityModal from '@/components/EditEntityModal.vue'
import FormInput from '@pixelstein/ps-form/components/PsFormInput'

/**
 * @mixes FileTypes
 * @mixes ViewDataCache
 */
export default {
  name: 'FilesModule',
  mixins: [FileTypes, ViewDataCache],
  components: {
    FormInput,
    EditEntityModal,
    ConfirmationModal,
    TagFilter,
    FileMetaEditor,
    MultiLayoutList,
    FormCheckbox,
    DropDown,
    SvgIcon,
    FileDirectoryMenu,
    SearchBar,
    MultiFileUpload,
    GeneralModal,
    ConfirmedClickButton,
    GeneralCard,
    GeneralListItem,
    LoadingScreen,
  },
  data () {
    return {
      lock: true,
      searchQuery: '',
      lastSearchQuery: '',
      loading: true,
      fileReferenceToEdit: null,
      fileReferenceToDelete: null,
      currentlyLoadedFileName: '',
      activeTags: {},
      selectedDirectoryId: '',
      open: false,
      pageLoading: false,
      page: 1,
      itemsPerPage: 20,
      scrollPos: 0,
      addFileThumbnail,
      instanceLimits: null,
      dropDownItem: null,
      showDirectoryModal: false,
    }
  },
  computed: {
    ...mapState({
      directories: state => state.Api.Directories.all,
      tags: state => state.Api.Tags.all,
      fileReferences: state => state.Api.FileReferences.all,
      user: state => state.user,
      limits: state => state.Api.Instance,
      authToken: state => state.Api.authToken,
    }),
    itemOptions () {
      const options = [
        {
          group: 'default',
          groupLabel: 'default',
          value: 'open',
          label: this.$t('files.file_toolbar.open_file'),
          active: false,
        },
      ]

      if (hasPermission(this.user, 'FileReferences', ACTION_UPDATE)) {
        options.push(
            {
              group: 'default',
              groupLabel: 'default',
              value: 'edit',
              label: this.$t('files.file_toolbar.edit_file'),
              active: false,
            },
            {
              group: 'default',
              groupLabel: 'default',
              value: 'delete',
              label: this.$t('files.file_toolbar.delete_file'),
              active: false,
            },
        )
      }

      return options
    },
    cacheableKeys () {
      return ['activeTags', 'searchQuery', 'selectedDirectoryId']
    },
    maxUploadFileSize () {
      return this.instanceLimits?.max_upload_file_size ?? this.$config.UPLOAD_MAX_FILE_SIZE
    },
    maxUploadsPerRequest () {
      return this.instanceLimits?.max_uploads_per_request ?? this.$config.MAX_UPLOADS_PER_REQUEST
    },
    fileReferencesOfSelectedDirectory () {
      let fileReferences = _cloneDeep(this.fileReferences)
          .filter((file, idx, array) => idx === array.findIndex(f => f.id === file.id))

      if (this.selectedDirectoryId) {
        fileReferences = fileReferences.filter(fr => fr.directories.find(d => d.id === this.selectedDirectoryId))
      }

      return fileReferences
    },
    filteredFileReferences () {
      if (this.searchQuery !== this.lastSearchQuery) {
        this.lastSearchQuery = this.searchQuery
        this.loading = true

        const regex = new RegExp(this.searchQuery, 'i')
        const filteredFiles = this.fileReferencesOfSelectedDirectory
            .filter(file => !!file?.title?.match(regex) || !!file?.name?.match(regex))
            .filter(file => this.activeFileTypes.length === 0 || this.activeFileTypes.includes(file?.file?.type))

        setTimeout(() => this.loading = false, 1000)

        return filteredFiles
      } else {
        // loading is initially true
        setTimeout(() => this.loading = false, 1000)

        return  this.fileReferencesOfSelectedDirectory
      }
    },
    tagsOfSelectedDirectory () {
      return this.filteredFileReferences
          .flatMap(fileReference => fileReference.tags)
          .filter((tag, index, array) => index === array.findIndex(t => t.id === tag.id))
    },
    tagFilteredFileReferences () {
      const tagsToFilter = this.tagsOfSelectedDirectory.filter(tag => !!this.activeTags[tag.id])

      if (tagsToFilter.length === 0) {
        return this.filteredFileReferences
      }

      // separate computed to prevent "too much recursion" issue
      return this.filteredFileReferences
          .filter(fileReference => {
            return fileReference?.tags.length > 0
                && tagsToFilter.every(tag => fileReference?.tags.find(t => t.id === tag.id))
          })
    },
    mappedFileReferences () {
      return this.tagFilteredFileReferences.map(fileReference => {
        if (fileReference.file && !fileReference.default_thumbnail) {
          fileReference.default_thumbnail = this.getDefaultThumbnail(fileReference.file)
        }

        if (fileReference.file?.name && !fileReference.title) {
          fileReference.title = fileReference.file?.name
        }

        return fileReference
      })
    },
    selectedDirectory () {
      return this.directories?.find(g => g.id === this.selectedDirectoryId)
    },
    filteredTagsOfSelectedDirectory () {
      const tagsUsedInFiles = this.tagFilteredFileReferences
          .flatMap(fileReference => fileReference.tags)
          .filter((tag, index, array) => index === array.findIndex(t => t.id === tag.id))

      return this.tagsOfSelectedDirectory
          .filter(tag => !!tagsUsedInFiles.find(t => t.id === tag.id))
    },
    fileTypes () {
      return [...new Set(this.fileReferencesOfSelectedDirectory.map(file => file?.file?.type))]
    },
    filteredGroupedFileTypes () {
      return this.groupedFileTypes.filter(group => group.mimeTypes.some(mime => this.fileTypes.includes(mime)))
    },
    sortedFilteredGroupedFileTypes () {
      return this.filteredGroupedFileTypes
          .toSorted((a, b) => a.title.localeCompare(b.title))
    },
    fileTypeOptions () {
      return this.sortedFilteredGroupedFileTypes
          .map(type => ({ label: type.title, value: type.title }))
    },
    checkedFileTypes () {
      return this.filteredGroupedFileTypes
          .filter(type => type.active)
          .map(type => ({ [type.title]: true }))
          .reduce((accu, config) => ({ ...accu, ...config }), {})
    },
    activeFileTypes () {
      return this.filteredGroupedFileTypes
          .filter(g => g.active)
          .flatMap(g => g.mimeTypes)
    },
    canUploadFiles () {
      return !!hasPermission(this.user, 'Files', ACTION_CREATE)
          && !!hasPermission(this.user, 'FileReferences', ACTION_CREATE)
    },
    canEditDirectories () {
      return !!hasPermission(this.user, 'Directories', ACTION_UPDATE)
          && !!hasPermission(this.user, 'Directories', ACTION_DELETE)
    },
  },
  watch: {
    activeTags: {
      deep: true,
      handler () {
        this.resetPagination()
      },
    },
    selectedDirectoryId () {
      this.resetPagination()
    },
    groupedFileTypes: {
      deep: true,
      handler () {
        this.resetPagination()
      },
    },
  },
  methods: {
    ...mapActions({
      addDirectory: 'Api/Directories/add',
      editDirectory: 'Api/Directories/edit',
      deleteDirectory: 'Api/Directories/delete',
      getFileReferences: 'Api/FileReferences/index',
      addFileReference: 'Api/FileReferences/add',
      editFileReference: 'Api/FileReferences/edit',
      deleteFileReference: 'Api/FileReferences/delete',
      purgeFileReference: 'Api/FileReferences/purge',
      addFile: 'Api/Files/add',
      editFile: 'Api/Files/edit',
      deleteFile: 'Api/Files/delete',
      addTag: 'Api/Tags/add',
      getInstanceLimits: 'Api/Instance/limits',
    }),
    ...mapMutations({
      updateFileReference: 'Api/FileReferences/addOrUpdate'
    }),
    onOpenDirectoryModal () {
      this.showDirectoryModal = true
    },
    async onSaveDirectories (items) {
      const directoriesToUpdate = items
          .filter(dir => !dir.delete && !dir.new)

      const directoriesToDelete = items
          .filter(dir => dir.delete && !dir.new)

      const directoriesToAdd = items
          .filter(dir => !dir.delete && dir.new)

      const directoryIdsToDelete = directoriesToDelete.map(d => d.id)

      await Promise.all([
        directoriesToUpdate.map(dir => this.editDirectory(dir)),
        directoriesToDelete.map(dir => this.deleteDirectory(dir)),
        directoriesToAdd.map(dir => this.addDirectory(dir)),
      ])

      // remove the deleted directories from all fileReferences
      this.fileReferences
          .filter(f => f.directories.some(d => directoryIdsToDelete.includes(d.id)))
          .map(f => {
            const clone = _cloneDeep(f)

            clone.directories = clone.directories
                .filter(d => !directoryIdsToDelete.includes(d.id))

            return clone
          })
          .forEach(f => this.updateFileReference({ item: f }))

      this.showDirectoryModal = false
    },
    async onCreateDirectory (options) {
      if (!options.name) {
        return
      }

      try {
        const params = {
          name: options.name,
        }

        if (this.fileReferenceToEdit?.id) {
          params.file_references = [
            { id: this.fileReferenceToEdit.id },
          ]
          params.contain = ['file_references']
        }

        const directory = await this.addDirectory(params)

        options.onSuccessCallback?.(directory)
      } finally {
        options.onFinallyCallback?.()
      }
    },
    shouldDropLeft (id) {
      if (document.getElementById(id) && document.getElementById(id).offsetLeft < 5) {
        return false
      }
      return true
    },
    resetPagination () {
      if (this.tagFilteredFileReferences) {
        this.$refs.filelist.$refs.scroll_wrap.scroll({ top: 0 })
        this.scrollPos = 0
        this.page = 1
      }
    },
    async fetchLimits () {
      this.instanceLimits = await this.getInstanceLimits()
    },
    async createTag (options) {
      if (!options.name) {
        return
      }

      try {
        const tag = await this.addTag({
          name: options.name,
          file_references: [
            { id: this.fileReferenceToEdit.id },
          ],
          contain: ['file_references'],
        })

        options.onSuccessCallback?.(tag)
      } finally {
        options.onFinallyCallback?.()
      }
    },
    handleItemOption (action, fileReference) {
      switch (action.value) {
        case 'edit':
          if (!hasPermission(this.user, 'FileReferences', ACTION_UPDATE)) {
            break
          }

          this.fileReferenceToEdit = _cloneDeep(fileReference)
          this.fileReferenceToEdit.original_name = this.fileReferenceToEdit.name // name copy to display in modal title
          break
        case 'delete':
          if (!hasPermission(this.user, 'FileReferences', ACTION_UPDATE)) {
            break
          }

          this.fileReferenceToDelete = _cloneDeep(fileReference)
          this.fileReferenceToDelete.original_name = this.fileReferenceToDelete.name // name copy to display in modal title
          break
        case 'open':
          this.$router.push('/files/' + fileReference.file.id).catch(() => null)
          break
      }
    },
    setMimeGroupActive (value) {
      this.groupedFileTypes
          .forEach((type, index) => {
            if (value[type.title]) {
              this.$set(this.groupedFileTypes[index], 'active', true)
            } else {
              this.$set(this.groupedFileTypes[index], 'active', false)
            }
          })
    },
    updateActiveTags (tags) {
      const tagEntries = tags.map(({ id }) => [id, true])

      this.activeTags = Object.fromEntries(tagEntries)
    },
    async uploadFile (options) {
      if (
          !hasPermission(this.user, 'FileReferences', ACTION_CREATE)
          || !hasPermission(this.user, 'Files', ACTION_CREATE)
      ) {
        return
      }

      for (let idx = 0; idx < options.files.length; idx++) {
        try {
          const file = options.files[idx]

          const fileResult = await this.addFile({
            file: file.file,
            title: file.fileReference.name,
            $uploadProgressCallback: p => {
              options.onProgress(idx, (p.loaded / p.total * 100))
            },
          })

          await this.addFileReference({
            contain: ['tags', 'directories', 'files'],
            file_id: fileResult.id,
            ...file.fileReference,
          })

          options.onSuccessCallback?.(idx)

          await this.getFileReferences({
            contain: ['directories', 'files', 'tags'],
          })
        } catch (e) {
          options.onErrorCallback?.(idx)

          throw e
        }
      }

      options.onFinallyCallback()
    },
    async tryEditFileReference (fileReference) {
      await this.editFileReference({ ...fileReference, contain: ['tags', 'directories'], $merge: true })
      this.fileReferenceToEdit = null
    },
    async tryDeleteFileReference (fileReference) {
      await this.deleteFileReference(fileReference)
      this.fileReferenceToDelete = null
      this.fileReferenceToEdit = null
    },
  },
  async mounted () {
    if (this.tagFilteredFileReferences) {
      this.$nextTick(() => this.$refs.filelist.$refs.scroll_wrap.scroll({
        top: this.scrollPos,
      }))
    }

    if (this.authToken) {
      await this.fetchLimits()
    }

    this.$store.subscribe((mutation) => {
      if (mutation.type === 'Api/setAuthToken') {
        if (mutation.payload !== null) {
          this.fetchLimits()
        }
      }
    })
  },
}
</script>
