<template>
  <div class="form-content-composer-options-input">
    <form-select
        :label="$t('form_content_composer.options_input.label.type')"
        :options="inputTypes"
        option-value-key="value"
        :value="value.type"
        required
        @input="onInput('type', $event)"
    />

    <form-input
        :label="$t('form_content_composer.options_input.label.name')"
        :value="value.name"
        required
        @input="onInput('name', $event)"
    />

    <input hidden :required="keyNotUnique"/>
    <div v-if="keyNotUnique" class="ps-form-partial-input-info mb-3">
      <span class="invalid-note invalid">{{ $t('key_not_unique') }}</span>
    </div>

    <form-input
        :label="$t('form_content_composer.options_input.label.label')"
        :value="value.label"
        :required="!['file'].includes(value.type)"
        @input="onInput('label', $event)"
    />

    <template v-if="['file'].includes(value.type)">
      <form-input
          :label="$t('form_content_composer.options_input.label.file_button_first')"
          :value="value.buttonLabelFirstItem"
          required
          @input="onInput('buttonLabelFirstItem', $event)"
      />
      <form-input
          :label="$t('form_content_composer.options_input.label.file_button')"
          :value="value.buttonLabel"
          required
          @input="onInput('buttonLabel', $event)"
      />
    </template>

    <template v-if="['number', 'range'].includes(value.type)">
      <form-input
          :label="$t('form_content_composer.options_input.label.step')"
          :value="value.step"
          @input="onInput('step', $event)"
      />
      <form-input
          :label="$t('form_content_composer.options_input.label.min_value')"
          :value="value.minValue"
          @input="onInput('minValue', $event)"
      />
      <form-input
          :label="$t('form_content_composer.options_input.label.max_value')"
          :value="value.maxValue"
          @input="onInput('maxValue', $event)"
      />
    </template>

    <form-input v-if="![
        'file',
        'switch',
        'checkbox',
        'svg-checkbox',
        'svg-radio',
        'radio',
        'html',
        'textarea',
        'select',
        'range'
        ].includes(value.type)"
                :type="value.type"
                :min-value="value.minValue"
                :max-value="value.maxValue"
                :step="value.step"
                :label="$t('form_content_composer.options_input.label.default')"
                :value="value.default"
                @input="onInput('default', $event)"
    />

    <form-switch v-if="['switch'].includes(value.type)"
                 :label="$t('form_content_composer.options_input.label.default')"
                 :value="value.default"
                 @input="onInput('default', $event)"
    />

    <form-rich-text-editor v-if="['html'].includes(value.type)"
                           :label="$t('form_content_composer.options_input.label.default')"
                           :value="value.default"
                           @input="onInput('default', $event)"
    />

    <form-textarea v-if="['textarea'].includes(value.type)"
                   :label="$t('form_content_composer.options_input.label.default')"
                   :value="value.default"
                   @input="onInput('default', $event)"
    />

    <form-select v-if="['select'].includes(value.type)"
                 :label="$t('form_content_composer.options_input.label.default')"
                 :value="value.default"
                 :options="value.values"
                 option-value-key="value"
                 @input="onInput('default', $event)"
    />

    <form-range v-if="['range'].includes(value.type)"
                :label="$t('form_content_composer.options_input.label.default')"
                :value="value.default"
                :min-value="value.minValue"
                :max-value="value.maxValue"
                :step="value.step"
                @input="onInput('default', $event)"
    />

    <template v-if="['svg-radio', 'svg-checkbox'].includes(value.type)">
      <multi-file-upload
          class=" mb-2"
          :button-label-html-first-item="$t('report_editor.image')"
          :allowed-mime-types="['image/svg+xml']"
          :allowed-file-extensions="['.svg']"
          :max-file-count="1"
          :value="uploadValue"
          @input="uploadFile"
      />
    </template>

    <form-switch
        :label="$t('form_content_composer.options_input.label.required')"
        :value="value.required"
        @input="onInput('required', $event)"
    />

    <form-switch
        :label="$t('form_content_composer.options_input.label.cached')"
        :value="value.cached"
        @input="onInput('cached', $event)"
    />

    <template v-if="[
        'select',
        'checkbox',
        'radio',
        'text',
        'svg-checkbox',
        'svg-radio',
        ].includes(value.type)">
      <ps-accordion-collapse initial-active class="mb-2 bg-white">
        <template #header="{active}">
          <div class="p-2 d-flex align-items-center justify-content-between">
            {{ $t('form_content_composer.options_input.options.label') }}
            <i class="fas fa-chevron-left" :class="{active}" aria-hidden="true"></i>
          </div>
        </template>
        <template #content>
          <options-editor
              class="p-2"
              :value="optionEditorValues"
              :default="optionEditorDefault"
              :readonly="['svg-checkbox', 'svg-radio'].includes(value.type)"
              can-select-default
              @update-default="onUpdateOptionDefault"
              @input="onInput(['values'], $event)"
          />
        </template>
      </ps-accordion-collapse>
    </template>
  </div>
</template>

<script>
import _set from 'lodash/set'
import _cloneDeep from 'lodash/cloneDeep'

import FormSelect from '@pixelstein/ps-form/components/PsFormSelect'
import FormInput from '@pixelstein/ps-form/components/PsFormInput'
import FormSwitch from '@pixelstein/ps-form/components/PsFormSwitch'
import FormRichTextEditor from '@pixelstein/ps-form/components/PsFormRichTextEditor'
import FormTextarea from '@pixelstein/ps-form/components/PsFormTextarea'
import FormRange from '@pixelstein/ps-form/components/PsFormRange'
import PsAccordionCollapse from 'pixelstein-vue-app-package/src/vue2/PsAccordion/PsAccordionCollapse'
import MultiFileUpload from '@/components/Reports/MultiFileUpload.vue'
import OptionsEditor from '@/components/OptionsEditor.vue'
import { v1 } from 'uuid'
import { extractSvgOptions } from 'paperclip-lib/src/report-configs/ReportConfigUtils'

export default {
  name: 'FormContentComposerOptionsInput',
  components: {
    OptionsEditor,
    MultiFileUpload,
    FormSelect,
    FormInput,
    FormSwitch,
    FormRichTextEditor,
    FormTextarea,
    FormRange,
    PsAccordionCollapse,
  },
  props: {
    value: { type: Object, default: () => ({ values: [] }) },
    keyNotUnique: { type: Boolean, default: false },
    allowedMimeTypes: { type: Array, default: () => ['image/svg+xml'] },
    allowedFileExtensions: { type: Array, default: () => ['svg'] },
  },
  data () {
    return {
      sorting: '',
    }
  },
  watch: {
    value: {
      deep: true,
      handler (nv, ov) {
        if (nv.type === 'file' && ov.type !== 'file') {
          this.onInput('cssThumbnails', true)
        }
      },
    },
  },
  computed: {
    inputTypes () {
      return [
        { value: 'text', label: this.$t('form_content_composer.options_input.type.text') },
        { value: 'email', label: this.$t('form_content_composer.options_input.type.email') },
        { value: 'date', label: this.$t('form_content_composer.options_input.type.date') },
        { value: 'datetime-local', label: this.$t('form_content_composer.options_input.type.datetime_local') },
        { value: 'number', label: this.$t('form_content_composer.options_input.type.number') },
        { value: 'range', label: this.$t('form_content_composer.options_input.type.range') },
        { value: 'url', label: this.$t('form_content_composer.options_input.type.url') },
        { value: 'tel', label: this.$t('form_content_composer.options_input.type.tel') },
        { value: 'switch', label: this.$t('form_content_composer.options_input.type.switch') },
        { value: 'checkbox', label: this.$t('form_content_composer.options_input.type.checkbox') },
        { value: 'svg-checkbox', label: this.$t('form_content_composer.options_input.type.svg-checkbox') },
        { value: 'radio', label: this.$t('form_content_composer.options_input.type.radio') },
        { value: 'svg-radio', label: this.$t('form_content_composer.options_input.type.svg-radio') },
        { value: 'select', label: this.$t('form_content_composer.options_input.type.select') },
        { value: 'html', label: this.$t('form_content_composer.options_input.type.html') },
        { value: 'textarea', label: this.$t('form_content_composer.options_input.type.textarea') },
        { value: 'file', label: this.$t('form_content_composer.options_input.type.file') },
        { value: 'hidden', label: this.$t('form_content_composer.options_input.type.hidden') },
      ]
    },
    uploadValue () {
      if (this.value?.file) {
        return [this.value?.file]
      } else {
        return []
      }
    },
    optionEditorValues() {
      if (['svg-checkbox', 'svg-radio'].includes(this.value.type)) {
        return extractSvgOptions(this.value.svgData)
      } else {
        return this.value.values
      }
    },
    optionEditorDefault() {
      if (this.value.default === undefined) {
        return {}
      } else if (typeof this.value.default !== 'object') {
        return {
          [this.value.default]: true
        }
      } else {
        return this.value.default
      }
    },
  },
  methods: {
    onUpdateOptionDefault(value) {
      if (['checkbox', 'svg-checkbox'].includes(this.value.type)) {
        if (!this.value.default) {
          this.$set(this.value, 'default', {})
        }

        this.$set(this.value.default, value, !this.value.default[value])
      } else {
        if (this.value.default === value) {
          this.$set(this.value, 'default', '')
        } else {
          this.$set(this.value, 'default', value)
        }
      }
    },
    async uploadFile (newFiles) {
      if (newFiles?.progressingFiles?.length) {
        this.onInput('file', newFiles)
      } else {
        this.onInput('file')
      }
    },
    onInput (path, value) {
      const valueClone = _cloneDeep(this.value)

      _set(valueClone, path, value)

      if (path === 'file' && !value) {
        delete valueClone.file_id
        delete valueClone.options?.svgData
        delete valueClone.svgData
      }

      this.$emit('input', valueClone)
    },
  },
}
</script>
