<template>
  <div class="row h-100">
    <div class="col">
      <loading-screen/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import LoadingScreen from '@/components/LoadingScreen.vue'

export default {
  name: 'Logout',
  components: { LoadingScreen },
  props: {
    redirectPath: { type: String, default: '/login' },
  },
  methods: {
    ...mapActions({
      logout: 'Api/Users/logout',
    }),
    ...mapMutations({
      clearAuthToken: 'Api/clearAuthToken',
      setUser: 'setUser',
    }),
  },
  async mounted () {
    try {
      console.time('logout')
      await this.logout({})
      console.timeEnd('logout')
    } finally {
      this.clearAuthToken()

      this.setUser(null)

      window.localStorage.removeItem('nav')

      await this.$router.replace(this.redirectPath)
    }
  },
}
</script>
