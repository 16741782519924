<template>
  <div class="drop-down" :class="{dropup: dropUp}" @focusout="close()">
    <span @click.prevent.stop="toggleOpen()">
      <slot/>
    </span>
    <div class="dropdown-menu" :class="dropdownMenuClass">
      <template v-for="group in groupedItems">
        <span v-if="groupedItems.length > 1"
              class="dropdown-header"
              :key="group.groupName+'header'"
        >
          {{ group.groupName }}
        </span>
        <template v-for="item in group.items">
          <loading-screen v-if="loadingOption === item.value" :fullscreen="false" :height="30" :width="30"/>
          <a v-else
             :key="group.name+item.value"
             class="dropdown-item"
             :class="{active: item.active}"
             href="#"
             @click.prevent="emitClick($event, item)"
          >{{ item.label }}</a>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import LoadingScreen from '@/components/LoadingScreen.vue'

export default {
  name: 'DropDown',
  components: { LoadingScreen },
  props: {
    options: {
      type: Array, default: () => [],
    },
    closeOnChoose: { type: Boolean, default: false },
    dropUp: { type: Boolean, default: false },
    dropLeft: { type: Boolean, default: true },
    loadingOption: {type: String, default: ''},
    forceClose: {type: Boolean, default: false}
  },
  data () {
    return {
      open: false,
    }
  },
  watch: {
    forceClose: {
      deep: true,
      handler(nv) {
        if (nv === true) {
          this.close();
        }
      }
    }
  },
  computed: {
    dropdownMenuClass () {
      return {
        show: this.open,
        'dropdown-menu-right': this.dropLeft,
      }
    },
    groupedItems () {
      let groups = {}

      this.options.forEach(option => {
        if (!groups[option.groupLabel || option.group]) {
          groups[option.groupLabel || option.group || 'default'] = []
        }
        groups[option.groupLabel || option.group].push(option)
      })

      return Object.entries(groups)
          .map(([groupName, items]) => ({ groupName, items }))
    },
  },
  mounted () {
    document.addEventListener('click', this.close)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.close)
  },
  methods: {
    close () {
      this.open = false
    },
    emitClick (event, item) {
      if (!this.closeOnChoose) {
        event.stopPropagation()
      }
      this.$emit('click', item)
    },
    toggleOpen() {
      this.open = !this.open;
      if (this.open) {this.$emit('open')}
    }
  },
}
</script>
